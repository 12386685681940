<template>
 
  <ContentWrapper :editMode="editMode" :showTitleIcon="false" :content="false" @onClick="activeEdit = true"
    title="Latest productions" titleIcon="CameraIcon">
    <template #icon>
      <font-awesome-icon icon="fa-masks-theater" class="dark:text-white self-center mr-2 text-gray-400 dark:text-white" />
    </template>
    <template #default>
        <div v-if="production.length > 0">
        <div class="px-2">
          <div class="mt-4 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                 <table class="rounded-md min-w-full divide-y divide-gray-200 dark:divide-gray-800">
                    <thead class="table-auto bg-gray-50 dark:bg-primaryDark">
                      <tr>
                        <th scope="col"
                          class="pr-6 py-3 pl-3 min-width md:pl-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white">
                          <button class="uppercase">
                             Name / Type 
                             <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="#373F41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                        </th>
                        <th scope="col"
                          class="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white">
                          <button  class="uppercase">
                            Director
                            <!-- <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="#373F41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg> -->
                          </button>
                        </th>
                        <th scope="col"
                          class="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white min-width">
                          <button  class="uppercase">
                            Venue / Space
                            <svg width="8"   class="h-3 ml-2.5 w-3 inline-block text-gray-400 dark:text-white" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="#373F41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(prod, i) in production.slice().reverse()" :key="i" 
                      :class="i % 2 === 0? 'bg-white dark:bg-primaryDark': 'bg-gray-100 dark:bg-backgroundDark'"
                        class="py-4 whitespace-nowrap text-sm font-medium text-gray-900  hover:bg-gray-100 dark-hover:bg-gray-700 cursor-pointer bg-white dark:bg-primaryDark">
                        <!-- <div v-if="prod.isPrimary"> -->
                        <td class="py-2 text-left pl-3 pr-6 md:pl-6 whitespace-nowrap text-sm text-gray-500 dark:text-white whitespace-pre-wrap text-left	">
                          <div ><button class="text-left">
                              {{ prod.name }}
                            </button>
                            <p class="text-gray-400 whitespace-pre-wrap	">{{ prod.type }}</p>
                          </div>
                        </td>
                        <td class="py-2 whitespace-nowrap text-sm text-gray-500 dark:text-white pr-6">
                          <div class=" whitespace-pre-wrap text-left"> {{ prod.director }}</div>
                        </td>
                        <td class="py-2 whitespace-nowrap text-sm text-left text-gray-500 dark:text-white pr-6">
                          <div class="whitespace-pre-wrap	"> {{ prod.venue }}
                            <p class="text-gray-400">{{ prod.space }}</p>
                          </div>
                        </td>
                        <!-- </div> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full mb-5">
          <button type="button" @click="this.$props.navigation.[1].onClick(this.$props.navigation.[1]); this.$props.changeComponent(this.$props.navigation.[1].component)"
            className="float-right inline-flex items-center px-2.5 grey-slate py-1.5 dark-hover:text-grey-50 shadow-sm text-sm font-medium rounded text-gray-600 underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white">
            See more
          </button>
        
          <!-- <button class=" "></button> -->
        </div>
      </div>

   
     
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "../../../../widgets/ContentWrapper";
import { computed } from "vue";
import { useStore } from 'vuex'
 let newData='';
export default {
  name: "CompanyProductions",
  components: {
    ContentWrapper,
    
  },
    props: {
      changeComponent:{type:Function},
      
    editMode: {
      type: Boolean,
      required: true,
    },
    navigation:{
       type:Object,
    },
    userIsAdmin: {
        type: Boolean,
  
    },
    // company: {
    //   type: Object,
    // },
  },
  
  setup() {
    const store = useStore();
  
    return {
      production:computed(() => store.getters.getProductions),
      newData,
     
    };

   
  },
  
};
</script>