<template>
    <modal :modalActive="editActive" :duration="400" version="2" @close="$emit('onClose')">
        <div class="mb-9 p-4 bg-white">
            <p class="text-2xl text-left ml-2 font-medium mb-4">Edit General Info</p>
            <div class="w-full grid grid-cols-2 gap-5">
                <!-- {{ general }}/ -->
                <div class="bg-white space-y-6 col-span-full dark:text-white text-left dark:bg-primaryDark">

                    <div class="grid grid-cols-6 gap-6 ">
                        <SelectInput heading="Company Type" class="col-span-6 sm:col-span-3"
                            @update:value="updateGeneral.type = $event" :value="updateGeneral.type"
                            :options="companyTypes" />
                        <SelectInput heading="Company Level" class="col-span-6 sm:col-span-3"
                            @update:value="updateGeneral.level = $event" :value="updateGeneral.level"
                            :options="companyLevels" />
                    </div>

                    <div class="grid grid-cols-6 gap-6 ">
                        <DateInput class="col-span-3" heading="Founded" :value="updateGeneral.founded"
                            @update:value="updateGeneral.founded = $event" />
                        <DateInput class="col-span-3" heading="Dissolved" :value="updateGeneral.dissolved"
                            @update:value="updateGeneral.dissolved = $event" />
                    </div>

                    <SelectInput class="col-span-6 sm:col-span-3" heading="Country"
                        @update:value="updateGeneral.country = $event" :value="updateGeneral.country"
                        :options="countries" />
                </div>

                <!-- <button @click="saveCompanyBio">Test</button> -->
                <SaveCancelForm class="col-span-2" @onSave="saveCompanyGeneral" @onCancel="$emit('onClose')"
                    justifyOrientation="end" :display="true" :transparentButtons="true"/>
            </div>
        </div>
    </modal>

</template>

<script>
import { useStore } from 'vuex'
import { computed } from "vue";
import Modal from "../../../../widgets/Modal.vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import SelectInput from "@/components/inputs/SelectInput";
import DateInput from "@/components/inputs/DateInput";
export default {
    name: "CompanyGeneralEdit",
    components: {
        Modal,
        SaveCancelForm,
        SelectInput,
        DateInput
    },
    props: {
        general: {
            type: Object,
        },
        editActive: {
            type: Boolean,
        }
    },
    data() {
        return {
            updateGeneral: {...this.general},
        };
    },
    methods: {
        async saveCompanyGeneral() {
            await this.$store.dispatch("editCompany", { data: { ...this.updateGeneral } })
            await this.$store.dispatch("fetchCompany", this.$route.params.id)
            this.$emit("onClose")
        }
    },
    setup() {
        const store = useStore()
        store.dispatch("fetchCompanyTypes");
        store.dispatch("fetchCompanyLevels");
        store.dispatch("")
        return {
            companyTypes: computed(() => store.getters.getCompanyTypes),
            companyLevels: computed(() => store.getters.getCompanyLevels),
            countries: computed(() => store.getters.getCountries),
        }
    }
}
</script>