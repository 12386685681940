export const addressValidator = validator => list => list.every(element => {
    const validCountry = validator.country.required.$validator(element.country)
    const validCity = validator.city.required.$validator(element.city)

    if ((validCountry && validCity)) return true
    element.errors = {
        country: { hasError: !validCountry, message: "Country is required"},
        city: {hasError: !validCity, message: "City is required"}
    }
    return false
})

export const emailValidator = validator => list => list.every(element => {
    const res = validator.email.email.$validator(element.email)
    if(!res){
        element.error = {hasError: true, message: 'Must be a valid url'}
    } else {
        element.error = {hasError: false}
    }
    return res
})

export const websiteValidator = validator => list => list.every(element => {
    const res = validator.url.required.$validator(element.url)
    if(element.url === '') return true
    if(!res){
        element.error = {hasError: true, message: 'Must be a valid url'}
    } else {
        element.error = {hasError: false}
    }
    return res
})