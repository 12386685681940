<template>
    <!-- General -->
    <div class="p-4" id="General" role="tabpanel" aria-labelledby="General-tab">
        <h1 class="md:text-3xl text-2xl font-medium dark:text-white">
            Edit general information
        </h1>
    </div>
    <div class="px-12">
        <p class="text-sm dark:text-white px-4 mt-2">
            Here you can fill in all your basic information, such as your name, stage name, contact info etc. You can
            control which of these are displayed in the header of your profile, but they are also your contact
            information
            for contacts sheets etc. If allowed, it will be possible to download a .vcard file with contact information
            to
            insert into phone and/or computer contacts directory.
        </p>
        <h2 class="text-md font-bold pt-4 dark:text-white px-4"> Basic information </h2>
        <form class="md:w-full lg:w-full " @submit.prevent="onSubmit">
            <div class="flex py-4 w-full md:flex-row flex-col px-2">
                <div class="md:w-3/12 w-12/12 px-2">
                    <SmallTextInput heading="Company Name " :tooltip="true" toolText="Edit company name"
                        class="col-span-6" :value="state.name" @update:value="state.name = $event"
                        :errors="v$.name.$errors" />
                    <DateInput class="col-span-3 mt-4" heading="Date Founded" :tooltip="true"
                        toolText="Edit date founded" :value="state.founded" @update:value="state.founded = $event" />

                </div>
                <div class="md:w-3/12 px-2">
                    <SmallTextInput heading="English Name" class="col-span-6" :tooltip="true"
                        toolText="Edit english name" :value="state.englishName"
                        @update:value="state.englishName = $event" />

                    <DateInput class="col-span-3 mt-4" heading="Date Dissolved" :tooltip="true"
                        toolText="Edit date dissolved" :value="state.dissolved"
                        @update:value="state.dissolved = $event" />
                </div>
                <div class="md:w-3/12 w-12/12 px-2">
                    <SmallTextInput heading="Place founded" class="col-span-6 " :tooltip="true"
                        toolText="Edit place founded" :value="state.place" @update:value="state.place = $event"
                        :errors="v$.name.$errors" />
                    <div class="col-span-6 dark:text-white mt-4">
                        <SelectInput heading="Company Type" :tooltip="true" toolText="Select company type"
                            class="col-span-6 sm:col-span-3 gap-1 dark:text-white" @update:value="state.type = $event"
                            :value="state.type" :options="companyTypes" :errors="v$.type.$errors" />
                    </div>
                </div>
                <div class="md:w-3/12 w-12/12 px-2">
                    <SelectInput heading="Company Level" :tooltip="true" textFormate="capitalize"
                        toolText="Select company level" class="col-span-6  gap-1 sm:col-span-3"
                        @update:value="state.level = $event" :value="state.level" :options="companyLevel"
                        :errors="v$.level.$errors" />
                </div>

            </div>

            <SaveCancelForm class="col-span-2 col-start-3 pb-5" :display="true" justifyOrientation="end"
                    @onSave="onSubmit" @onCancel="$emit('onClose')"  />

            
        </form>
    </div>
</template>
<script>
import { useStore, mapGetters } from "vuex";
import { computed, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import SelectInput from "@/components/inputs/SelectInput";
import DateInput from "@/components/inputs/DateInput";
// import { notify } from "@kyvg/vue3-notification";
import { createToaster } from "@meforma/vue-toaster";
import SaveCancelForm from "@/components/forms/SaveCancelForm"

export default {
    name: "General",
    data() {
        return {
            editActive: true,

        };
    },
    computed: {
        ...mapGetters(["getCompanyPhoneNumbers"])
    },
    components: {
        DateInput,
        SelectInput,
        SmallTextInput,
        SaveCancelForm

    },
    async beforeMount() {
        await this.$store.dispatch('fetchCompanyTypes')
        await this.$store.dispatch('fetchCompanyLevels')
    },
    setup() {
        const store = useStore();
        const currentCompany = store.getters.getCompany;
        const state = reactive({
            name: currentCompany.name,
            englishName: currentCompany.englishName,
            place: '',
            founded: currentCompany.founded,
            dissolved: currentCompany.dissolved,
            type: currentCompany.type,
            level: currentCompany.level,


        });
        const rules = {
            name: { required },
            type: { required },
            level: { required },


        };

        console.log();
        const v$ = useVuelidate(rules, state, { $lazy: true });

        return {
            v$,
            state,
            store,
            companyLevel: computed(() => store.getters.getCompanyLevels),
            companyTypes: computed(() => store.getters.getCompanyTypes),
            countries: computed(() => store.getters.getCountries),



        };
    },
    methods: {

        async onSubmit() {
            const toast = createToaster({position:"top-right"});
            this.v$.$touch()

            if (this.v$.$error) {
                // window.scrollTo(0, this.$refs.top.offsetTop)
                // notify({
                //     title: "Something went wrong",
                //     text: "Some required fields were not provided",
                //     type: 'warn',
                //     duration: 2000
                // })
                toast.error(`Some required fields were not provided`);
                return
            }
            let formData=this.state;
            await this.$store.dispatch('editCompany',formData)

            // notify({
            //     text: "Company has been created successfully 🎉",
            //     type: "success",
            //     duration: 2000
            // })
            toast.success(`General info has been updated successfully`);

            await this.$router.push({ name: 'Company', params: { id: this.$store.getters.getCompanyId } })
        }
    }
};
</script>