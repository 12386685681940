<template>
<div :class="contentarea">
    <div
      :id="title"
      class="bg-white shadow rounded-lg py-5 px-6  dark:bg-primaryDark dark:text-white"
    >
      <div class="flex justify-between">
        <div class="flex">
          <component :is="titleIcon" v-if="showTitleIcon" class="w-4 mr-2  text-gray-400 dark:text-white" />
          <slot name="icon"></slot>
          <h2 :class="titleClasses" class="font-medium">{{ title }}</h2>
        </div>
        <button @click="$emit('onClick', true)" class="btn btn-sm edit_button text-blue-500 flex" v-if="editMode">
          <span v-if="funType == 'edit'">
            <PencilIcon v-if="!!content" class="w-4 mt-1 dark:text-white" />
          </span>
          <span class="mr-1" v-else-if="funType == 'add'">
            + New {{ title }}
          </span>
        </button>
      </div>
      <slot></slot>
    </div>
</div>
</template>

<script>
import { PencilIcon, UserIcon, CameraIcon, UsersIcon ,  IdentificationIcon , LinkIcon, KeyIcon,HomeIcon} from "@heroicons/vue/outline/esm"
export default {
  name: "ContentWrapper",
  components: {
    PencilIcon,
    IdentificationIcon,
    LinkIcon,
    KeyIcon,
    UserIcon,
    CameraIcon,
    UsersIcon,
    HomeIcon
  },
  computed:{
    titleClasses(){
      if(this.funType == 'add') return 'text-xl'
      return 'text-sm'
    },
    contentarea(){
      if(!this.contentClass)return 'mt-5'
      return '';
    }
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    content:{
      type: Boolean,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    funType:{
      required: false,
      default: "edit"
    },
    showTitleIcon: {
      required: false,
      default: false
    },
    titleIcon:{
      required: false,
      default: ''
    },
    contentClass:{
      type: Boolean,
      default: false
    }
  },
};
</script>
<style>

</style>
