<template>
    
      <!-- Travel -->
      <div class="p-4" id="General" role="tabpanel" aria-labelledby="General-tab">
        <h1 class="md:text-3xl text-2xl font-medium dark:text-white">
          Edit travel information
        </h1>
        <p class="text-sm pt-3 dark:text-white">
          Please note - Travel information will be hidden from anybody but you by default.
        </p>
        <form class="md:w-full lg:w-full">

          <div class="table_1">
            <div class="flex justify-between items-end mb-5">
              <div>
                <h2 class="text-md font-bold py-4 dark:text-white flex items-center"> Company members<svg class="mx-2"
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="7.5" fill="white" stroke="black"></circle>
                    <path
                      d="M7.26705 10.4347V10.3239C7.26989 9.80966 7.31818 9.40057 7.41193 9.09659C7.50852 8.79261 7.64489 8.5483 7.82102 8.36364C7.99716 8.17614 8.21165 8.00426 8.46449 7.84801C8.63778 7.74006 8.79261 7.62216 8.92898 7.49432C9.06818 7.36364 9.17756 7.21875 9.2571 7.05966C9.33665 6.89773 9.37642 6.71733 9.37642 6.51847C9.37642 6.29403 9.32386 6.09943 9.21875 5.93466C9.11364 5.76989 8.97159 5.64205 8.79261 5.55114C8.61648 5.46023 8.41903 5.41477 8.20028 5.41477C7.99858 5.41477 7.80682 5.45881 7.625 5.54688C7.44602 5.6321 7.29688 5.76278 7.17756 5.93892C7.06108 6.11222 6.99574 6.33239 6.98153 6.59943H5.46449C5.47869 6.05966 5.60938 5.60795 5.85653 5.24432C6.10653 4.88068 6.43608 4.60795 6.84517 4.42614C7.2571 4.24432 7.71165 4.15341 8.20881 4.15341C8.75142 4.15341 9.22869 4.24858 9.64062 4.43892C10.0554 4.62926 10.3778 4.90057 10.608 5.25284C10.8409 5.60227 10.9574 6.01705 10.9574 6.49716C10.9574 6.82102 10.9048 7.1108 10.7997 7.36648C10.6974 7.62216 10.5511 7.84943 10.3608 8.0483C10.1705 8.24716 9.9446 8.42472 9.68324 8.58097C9.45313 8.72301 9.2642 8.87074 9.11648 9.02415C8.97159 9.17756 8.86364 9.35795 8.79261 9.56534C8.72443 9.76989 8.68892 10.0227 8.68608 10.3239V10.4347H7.26705ZM8.00852 13.0938C7.75284 13.0938 7.53267 13.0028 7.34801 12.821C7.16335 12.6392 7.07102 12.4176 7.07102 12.1562C7.07102 11.9006 7.16335 11.6818 7.34801 11.5C7.53267 11.3182 7.75284 11.2273 8.00852 11.2273C8.26136 11.2273 8.48011 11.3182 8.66477 11.5C8.85227 11.6818 8.94602 11.9006 8.94602 12.1562C8.94602 12.3295 8.90199 12.4872 8.81392 12.6293C8.72869 12.7713 8.61506 12.8849 8.47301 12.9702C8.33381 13.0526 8.17898 13.0938 8.00852 13.0938Z"
                      fill="black"></path>
                  </svg></h2>
                <p class="dark:text-white">Please add information about your company members. Especially important are
                  dietary and travel restriction information.</p>
              </div>
              <div class="w-100"><button type="button"
                  class="flex flex-row items-center mr-2 px-3 pr-5 py-3  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1">
                  <div class="w-10 h-auto"><svg class="svg-inline--fa fa-plus" aria-hidden="true" focusable="false"
                      data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512">
                      <path class="" fill="currentColor"
                        d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
                      </path>
                    </svg></div>
                  <div class="hidden sm:block sm:mx-1 w-24">Add document</div>
                  <!-- <div class="px-0.5">
              </div> -->
                </button></div>
            </div>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      Product name
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Category
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class=" w-full">
              <div class="py-2 text-right"><button type="button"
                  class="mr-2 py-3 px-8 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-gray-500 text-white hover:bg-gray-50 hover:text-gray-500 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1">
                  <div>Export as PDF</div>
                  <!-- <div class="px-0.5">
          </div> -->
                </button></div>
            </div>
          </div>
          <div class="table_2">
            <div class="flex justify-between items-end mb-5">
              <div>
                <h2 class="text-md font-bold py-4 dark:text-white flex items-center">Company members<svg class="mx-2"
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="7.5" fill="white" stroke="black"></circle>
                    <path
                      d="M7.26705 10.4347V10.3239C7.26989 9.80966 7.31818 9.40057 7.41193 9.09659C7.50852 8.79261 7.64489 8.5483 7.82102 8.36364C7.99716 8.17614 8.21165 8.00426 8.46449 7.84801C8.63778 7.74006 8.79261 7.62216 8.92898 7.49432C9.06818 7.36364 9.17756 7.21875 9.2571 7.05966C9.33665 6.89773 9.37642 6.71733 9.37642 6.51847C9.37642 6.29403 9.32386 6.09943 9.21875 5.93466C9.11364 5.76989 8.97159 5.64205 8.79261 5.55114C8.61648 5.46023 8.41903 5.41477 8.20028 5.41477C7.99858 5.41477 7.80682 5.45881 7.625 5.54688C7.44602 5.6321 7.29688 5.76278 7.17756 5.93892C7.06108 6.11222 6.99574 6.33239 6.98153 6.59943H5.46449C5.47869 6.05966 5.60938 5.60795 5.85653 5.24432C6.10653 4.88068 6.43608 4.60795 6.84517 4.42614C7.2571 4.24432 7.71165 4.15341 8.20881 4.15341C8.75142 4.15341 9.22869 4.24858 9.64062 4.43892C10.0554 4.62926 10.3778 4.90057 10.608 5.25284C10.8409 5.60227 10.9574 6.01705 10.9574 6.49716C10.9574 6.82102 10.9048 7.1108 10.7997 7.36648C10.6974 7.62216 10.5511 7.84943 10.3608 8.0483C10.1705 8.24716 9.9446 8.42472 9.68324 8.58097C9.45313 8.72301 9.2642 8.87074 9.11648 9.02415C8.97159 9.17756 8.86364 9.35795 8.79261 9.56534C8.72443 9.76989 8.68892 10.0227 8.68608 10.3239V10.4347H7.26705ZM8.00852 13.0938C7.75284 13.0938 7.53267 13.0028 7.34801 12.821C7.16335 12.6392 7.07102 12.4176 7.07102 12.1562C7.07102 11.9006 7.16335 11.6818 7.34801 11.5C7.53267 11.3182 7.75284 11.2273 8.00852 11.2273C8.26136 11.2273 8.48011 11.3182 8.66477 11.5C8.85227 11.6818 8.94602 11.9006 8.94602 12.1562C8.94602 12.3295 8.90199 12.4872 8.81392 12.6293C8.72869 12.7713 8.61506 12.8849 8.47301 12.9702C8.33381 13.0526 8.17898 13.0938 8.00852 13.0938Z"
                      fill="black"></path>
                  </svg></h2>
                <p class="dark:text-white">Please add information about your company members. Especially important are
                  dietary and travel restriction information.</p>
              </div>
              <div class="w-100"><button type="button"
                  class="flex flex-row items-center mr-2 px-3 pr-5 py-3  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1">
                  <div class="w-10 h-auto"><svg class="svg-inline--fa fa-plus" aria-hidden="true" focusable="false"
                      data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512">
                      <path class="" fill="currentColor"
                        d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
                      </path>
                    </svg></div>
                  <div class="hidden sm:block sm:mx-1 w-24">Add document</div>
                  <!-- <div class="px-0.5">
              </div> -->
                </button></div>
            </div>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      Product name
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Category
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      <svg class="opacity-30" width="19" height="20" viewBox="0 0 19 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.1716 5L5.58579 11.5858C4.80474 12.3668 4.80474 13.6332 5.58579 14.4142C6.36684 15.1953 7.63317 15.1953 8.41421 14.4142L14.8284 7.82843C16.3905 6.26633 16.3905 3.73367 14.8284 2.17157C13.2663 0.609476 10.7337 0.609476 9.17157 2.17157L2.75736 8.75736C0.414214 11.1005 0.414214 14.8995 2.75736 17.2426C5.1005 19.5858 8.89949 19.5858 11.2426 17.2426L17.5 11"
                          stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      <svg class="opacity-30" width="19" height="20" viewBox="0 0 19 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.1716 5L5.58579 11.5858C4.80474 12.3668 4.80474 13.6332 5.58579 14.4142C6.36684 15.1953 7.63317 15.1953 8.41421 14.4142L14.8284 7.82843C16.3905 6.26633 16.3905 3.73367 14.8284 2.17157C13.2663 0.609476 10.7337 0.609476 9.17157 2.17157L2.75736 8.75736C0.414214 11.1005 0.414214 14.8995 2.75736 17.2426C5.1005 19.5858 8.89949 19.5858 11.2426 17.2426L17.5 11"
                          stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      <svg class="opacity-30" width="19" height="20" viewBox="0 0 19 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.1716 5L5.58579 11.5858C4.80474 12.3668 4.80474 13.6332 5.58579 14.4142C6.36684 15.1953 7.63317 15.1953 8.41421 14.4142L14.8284 7.82843C16.3905 6.26633 16.3905 3.73367 14.8284 2.17157C13.2663 0.609476 10.7337 0.609476 9.17157 2.17157L2.75736 8.75736C0.414214 11.1005 0.414214 14.8995 2.75736 17.2426C5.1005 19.5858 8.89949 19.5858 11.2426 17.2426L17.5 11"
                          stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      <svg class="opacity-100" width="19" height="20" viewBox="0 0 19 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.1716 5L5.58579 11.5858C4.80474 12.3668 4.80474 13.6332 5.58579 14.4142C6.36684 15.1953 7.63317 15.1953 8.41421 14.4142L14.8284 7.82843C16.3905 6.26633 16.3905 3.73367 14.8284 2.17157C13.2663 0.609476 10.7337 0.609476 9.17157 2.17157L2.75736 8.75736C0.414214 11.1005 0.414214 14.8995 2.75736 17.2426C5.1005 19.5858 8.89949 19.5858 11.2426 17.2426L17.5 11"
                          stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class=" w-full">
              <div class="py-2 text-right"><button type="button"
                  class="mr-2 py-3 px-8 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-gray-500 text-white hover:bg-gray-50 hover:text-gray-500 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1">
                  <div>Export as PDF</div>
                  <!-- <div class="px-0.5">
          </div> -->
                </button></div>
            </div>
          </div>
          <div class="table_3">
            <div class="flex justify-between items-end mb-5">
              <div>
                <h2 class="text-md font-bold py-4 dark:text-white flex items-center"> Travel hubs<svg class="mx-2"
                    width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="7.5" fill="white" stroke="black"></circle>
                    <path
                      d="M7.26705 10.4347V10.3239C7.26989 9.80966 7.31818 9.40057 7.41193 9.09659C7.50852 8.79261 7.64489 8.5483 7.82102 8.36364C7.99716 8.17614 8.21165 8.00426 8.46449 7.84801C8.63778 7.74006 8.79261 7.62216 8.92898 7.49432C9.06818 7.36364 9.17756 7.21875 9.2571 7.05966C9.33665 6.89773 9.37642 6.71733 9.37642 6.51847C9.37642 6.29403 9.32386 6.09943 9.21875 5.93466C9.11364 5.76989 8.97159 5.64205 8.79261 5.55114C8.61648 5.46023 8.41903 5.41477 8.20028 5.41477C7.99858 5.41477 7.80682 5.45881 7.625 5.54688C7.44602 5.6321 7.29688 5.76278 7.17756 5.93892C7.06108 6.11222 6.99574 6.33239 6.98153 6.59943H5.46449C5.47869 6.05966 5.60938 5.60795 5.85653 5.24432C6.10653 4.88068 6.43608 4.60795 6.84517 4.42614C7.2571 4.24432 7.71165 4.15341 8.20881 4.15341C8.75142 4.15341 9.22869 4.24858 9.64062 4.43892C10.0554 4.62926 10.3778 4.90057 10.608 5.25284C10.8409 5.60227 10.9574 6.01705 10.9574 6.49716C10.9574 6.82102 10.9048 7.1108 10.7997 7.36648C10.6974 7.62216 10.5511 7.84943 10.3608 8.0483C10.1705 8.24716 9.9446 8.42472 9.68324 8.58097C9.45313 8.72301 9.2642 8.87074 9.11648 9.02415C8.97159 9.17756 8.86364 9.35795 8.79261 9.56534C8.72443 9.76989 8.68892 10.0227 8.68608 10.3239V10.4347H7.26705ZM8.00852 13.0938C7.75284 13.0938 7.53267 13.0028 7.34801 12.821C7.16335 12.6392 7.07102 12.4176 7.07102 12.1562C7.07102 11.9006 7.16335 11.6818 7.34801 11.5C7.53267 11.3182 7.75284 11.2273 8.00852 11.2273C8.26136 11.2273 8.48011 11.3182 8.66477 11.5C8.85227 11.6818 8.94602 11.9006 8.94602 12.1562C8.94602 12.3295 8.90199 12.4872 8.81392 12.6293C8.72869 12.7713 8.61506 12.8849 8.47301 12.9702C8.33381 13.0526 8.17898 13.0938 8.00852 13.0938Z"
                      fill="black"></path>
                  </svg></h2>
              </div>
              <div class="w-100"><button type="button"
                  class="flex flex-row items-center mr-2 px-3 pr-5 py-3  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1">
                  <div class="w-10 h-auto"><svg class="svg-inline--fa fa-plus" aria-hidden="true" focusable="false"
                      data-prefix="fas" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512">
                      <path class="" fill="currentColor"
                        d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
                      </path>
                    </svg></div>
                  <div class="hidden sm:block sm:mx-1 w-24">Add document</div>
                  <!-- <div class="px-0.5">
              </div> -->
                </button></div>
            </div>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      Product name
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Category
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Price
                    </th>
                    <th scope="col" class="py-3 px-6">

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                    </th>
                    <td class="py-4 px-6">
                      Uk
                    </td>
                    <td class="py-4 px-6">
                      Aisle
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6">
                      none
                    </td>
                    <td class="py-4 px-6 flex">
                      <svg class="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.2008 1.79917L14.7311 1.26884V1.26884L14.2008 1.79917ZM3.875 14.7766V15.5266C4.07391 15.5266 4.26468 15.4476 4.40533 15.3069L3.875 14.7766ZM1.25 14.7766H0.5C0.5 15.1908 0.835786 15.5266 1.25 15.5266L1.25 14.7766ZM1.25 12.0983L0.71967 11.568C0.579018 11.7086 0.5 11.8994 0.5 12.0983H1.25ZM12.0795 2.3295C12.5188 1.89017 13.2311 1.89017 13.6705 2.3295L14.7311 1.26884C13.706 0.243718 12.0439 0.243718 11.0188 1.26884L12.0795 2.3295ZM13.6705 2.3295C14.1098 2.76884 14.1098 3.48116 13.6705 3.9205L14.7311 4.98116C15.7562 3.95603 15.7562 2.29397 14.7311 1.26884L13.6705 2.3295ZM13.6705 3.9205L3.34467 14.2463L4.40533 15.3069L14.7311 4.98116L13.6705 3.9205ZM3.875 14.0266H1.25V15.5266H3.875V14.0266ZM11.0188 1.26884L0.71967 11.568L1.78033 12.6286L12.0795 2.3295L11.0188 1.26884ZM0.5 12.0983V14.7766H2V12.0983H0.5ZM9.8938 3.4545L12.5455 6.10616L13.6061 5.0455L10.9545 2.39384L9.8938 3.4545Z"
                          fill="black" />
                      </svg>

                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.25 4.25L11.5995 13.3569C11.5434 14.1418 10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75 2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5 7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579 8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579 4.75 2V4.25M1 4.25H13"
                          stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class=" w-full">
              <div class="py-2 text-right"><button type="button"
                  class="mr-2 py-3 px-8 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-gray-500 text-white hover:bg-gray-50 hover:text-gray-500 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1">
                  <div>Export as PDF</div>
                  <!-- <div class="px-0.5">
          </div> -->
                </button></div>
            </div>
          </div>


        </form>
      </div>


      <div class="hidden p-4" id="settings" role="tabpanel" aria-labelledby="settings-tab">
        <p class="text-sm text-gray-500 dark:text-gray-400">
          This is some placeholder content the
          <strong class="font-medium text-gray-800 dark:text-white">Settings tab's associated content</strong>. Clicking
          another tab will toggle the visibility of this one for the
          next. The tab JavaScript swaps classes to control the content
          visibility and styling.
        </p>
      </div>
      <div class="hidden p-4" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
        <p class="text-sm text-gray-500 dark:text-gray-400">
          This is some placeholder content the
          <strong class="font-medium text-gray-800 dark:text-white">Contacts tab's associated content</strong>. Clicking
          another tab will toggle the visibility of this one for the
          next. The tab JavaScript swaps classes to control the content
          visibility and styling.
        </p>
      </div>
      
</template>