<template>
  <div class="flex-grow h-10 relative dark:bg-primaryDark dark:text-white" @focusout="turnOffSuggestions">
    <input @input="$emit('onInput', $event.target.value)"
           @focusin="turnOnSuggestions"
           ref="inp"
           :value="value"
           :type="type"
           :placeholder="capitalize(fieldName)"
           :class="[  isLast ? 'border-gray-200' : 'border-gray-200', hasError ? 'border-red-400' : '',fieldName=='label' || fieldName=='title'?'capitalize':'',fieldName=='url' || fieldName=='email'?'lowercase ':'']"
           class="flex-grow w-full relative p-2 mt-1 mb-1 border border-gray-300 rounded focus:outline-none dark:bg-primaryDark dark:text-white "
    />
      <div v-if="showSuggestions" @click="clickTracker" class="absolute z-50 flex flex-col bg-white px-2">
        <button v-for="(suggestion, idx) in suggestions" :key="idx" @click="$emit('onInput', suggestion)" class="my-2">
          {{ suggestion }}
        </button>
      </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "ContactInfoInputField",
  props: {
    suggestions: Array,
    value: String,
    fieldName: String,
    hasError: Boolean,
    isLast: Boolean,
    textFormate:String,
    type: String,
  },
  setup(props) {
    const suggestionsOn = ref(false)
    const fieldIsEmpty = ref((props.value === ''))
    const suggestionsClicked = ref(false)
    const suggestionField = (props.fieldName === 'label') // Make comparison string optional

    return {
      suggestionsOn,
      fieldIsEmpty,
      suggestionsClicked,
      showSuggestions: computed(() => suggestionsOn.value && suggestionField && fieldIsEmpty.value),
      turnOnSuggestions() {
          suggestionsOn.value = true
      },
      turnOffSuggestions() {
        setTimeout(() => {
          if(!suggestionsClicked.value) suggestionsOn.value = false
        },100)

      },
      capitalize(key){
        return key.replace(/^\w/, (c) => c.toUpperCase());
      },
      clickTracker(){
        suggestionsClicked.value = true
        setTimeout(() => {
          suggestionsClicked.value = false
        }, 100)
      }
    }
  },
  watch: {
    value: function(newVal){
      if(newVal === '') {
        this.fieldIsEmpty = true
      } else {
        this.fieldIsEmpty = false
      }
    }
  },
  mounted() {
    // Focus the input if it is the first one from the left and is empty
    // if(this.$props.value === '' && getCurrentInstance().vnode.key === 0) this.$refs.inp.focus()
  }
}
</script>
