<template>
  <!-- IMAGE MODAL -->
  <Modal
    :modalActive="companyAddPeople"
    label="user"
    :duration="400"
    version="2"
    @close="modalActive = false"
  >
    <LoadingSpinner v-if="isLoading" />
    <div v-else>
      <form @submit.prevent="onSubmit">
        <div class="w-full">
          <div
            class="overflow-hidden sm:rounded-md bg-white dark:bg-primaryDark md:p-10 p-5 m-2 add-production"
          >
            <div class="flex items-center mb-4 mr-12">
              <p class="whitespace-nowrap pr-2 font-semibold dark:text-white">
                Add People
              </p>
              <!-- <div class="h-px w-full bg-gray-400"></div> -->
            </div>

            <div class="gap-2 mt-10 dark:text-white">
              <MultiFieldAddPeopleInput
                heading="User"
                :tooltip="true"
                toolText="Add user"
                textFormate="capitalize"
                :suggestions="skillSuggestions"
                :elements="this.addPeopleArr"
                :addElement="addSkill"
                :removeElement="removeSkill"
              />

              <div class="flex py-2 md:py-4 w-full md:flex-row flex-col gap-2 md:gap-2">
                <!-- <div class="md:w-4/12 w-12/12 flex flex-col gap-2">
                  <SmallTextInput heading="Department" class="col-span-6" :value="form.department"
                    @update:value="form.department = $event" :tooltip="true" toolText="Add department" />


                </div> -->
                <div class="md:w-6/12 w-12/12 flex flex-col gap-2">
                  <DateInput
                    class="col-span-3"
                    heading="Start time"
                    :value="form.started"
                    @update:value="form.started = $event"
                    :tooltip="true"
                    toolText="Add Start time"
                  />
                </div>
                <div class="md:w-6/12 w-12/12 flex flex-col gap-2">
                  <DateInput
                    class="col-span-3"
                    heading="End time"
                    :value="form.left"
                    @update:value="form.left = $event"
                    :tooltip="true"
                    toolText="Add End time"
                  />
                </div>
              </div>
              <SaveCancelForm
                class="col-span-2"
                @onSave="onSubmit(this.addPeopleArr)"
                @onCancel="$emit('close')"
                justifyOrientation="end"
                :display="true"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </Modal>
</template>
<script>
/* eslint-disable no-unreachable */
import { useStore } from "vuex";
import { computed, reactive } from "vue";
import Modal from "@/components/widgets/Modal";
import LoadingSpinner from "@/components/widgets/LoadingSpinner";
// import SmallTextInput from "@/components/inputs/SmallTextInput";
import DateInput from "@/components/inputs/DateInput";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import MultiFieldAddPeopleInput from "@/components/inputs/MultiFieldAddPeopleInput";

export default {
  name: "CompanyAddPeopleModal",
  components: {
    Modal,
    LoadingSpinner,
    DateInput,
    SaveCancelForm,
    MultiFieldAddPeopleInput,
  },
  props: {
    companyAddPeople: {
      type: Object,
    },
  },
  async beforeMount() {
    let people = await this.$store.dispatch("getPeople", {
      name: "",
      first: 100,
      after: "",
      before: "",
    });
    this.$store.commit("setSearchPeople", people);
  },
  async updated() {
    await this.$store.dispatch("dynamicTableLoadCompanyMembers");
  },
  data() {
    return {
      editActive: true,
      currentPeople: "",
      addPeopleArr: [],
    };
  },

  methods: {
    removeArrayElement(arrayVal) {
      this.addPeopleArr.forEach((value, index) => {
        if (this.addPeopleArr[index].id === arrayVal) {
          delete this.addPeopleArr[index];
        }
      });

      this.addPeopleArr = this.addPeopleArr.filter((element) => {
        return element !== null;
      });
    },

    async addSkill(id, title) {
      if (id != "" && title != "") {
        var foundValue = this.addPeopleArr.filter((obj) => obj.id === id);

        if (foundValue.length > 0) {
          alert("this users already exists");
        } else {
          this.addPeopleArr.push({ name: title, id: id });
        }
      } else {
        this.addPeopleArr.push({ name: title, id: id });
      }

      this.addPeopleArr = this.addPeopleArr.filter((element) => {
        return element !== null;
      });
      this.currentPeople = "";
    },
    async onSubmit(addPeopleArr) {
      if (addPeopleArr.length > 0) {
        let newArr = [];
        addPeopleArr.find((o) => {
          newArr = {
            profileId: parseInt(o.id),
            companyId: parseInt(this.$store.getters.getCompanyId),
            role: "",
            // department: this.form.department,
            started: this.form.started,
            left: this.form.left === "" ? null : this.form.left,
          };
          console.log(newArr);
          this.$store.dispatch("saveCompanyMember", newArr);
        });
      }
      await this.$store.dispatch("fetchCompanyPeople", {
        ...this.$store.getters.getDynamicTableParams,
        companyId: this.$store.getters.getCompanyId,
      });

      this.addPeopleArr = [];
      this.$emit("close");
    },
    removeSkill(title) {
      this.removeArrayElement(title);
    },
  },

  setup() {
    const store = useStore();
    const form = reactive({
      profileId: null,
      department: null,
      started: null,
      left: null,
      role: null,
    });
    // const router = useRouter();
    return {
      form,
      store,
      skillSuggestions: computed(() => store.getters.getCardFormattedPeople),
      isLoading: computed(() => store.getters.isLoading),
      changeTitle(val) {
        store.dispatch("fetchPeople", { name: val, first: 10, after: "", before: "" });
      },
    };
  },
};
</script>
