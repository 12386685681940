<template>
  
  <div class="w-full my-1 flex items-start md:items-end justify-start  flex-col md:flex-row" >
    <SelectInput :options="socialTypes" :class="socialWidth" :value="capitalize(social.network)"
      @update:value="$emit('update:network', $event)" placeholder="Social Network" class="sm:pr-10 flex-2 pr-0 w-full md:w-6/12" />
    <div class="flex border rounded border-gray-300 h-full w-full md:w-6/12  my-2 md:my-0" v-if="social.network !== ''">
      <p class="h-full w-auto text-gray-400 pl-2 pr-1 py-1.5 text-md font-bold">@</p>
      <input placeholder="username" class="flex-1 border-none dark:bg-primaryDark focus:outline-none w-full" :value="social.username"
        @input="$emit('update:username', $event.target.value)" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import SelectInput from "@/components/inputs/SelectInput";

export default {
  name: "SocialsInput",
  components: { SelectInput },
  props: {
    social: Object,
    socialWidth: { type: String, required: false, default: "" },

  },
   setup() {
    const store = useStore()

    return {
      socialTypes: computed(() => store.getters.getSocialTypes),
    
      capitalize(key) {
        return key.replace(/^\w/, (c) => c.toUpperCase());
      },
    }
  }
}
</script>
