<template>
    <modal :modalActive="editActive" :duration="400" version="3" @close="$emit('onClose')">
        <div class="rounded-lg mb-9 p-4 bg-white dark:bg-primaryDark dark:text-white 
">
            <div class="text-2xl text-left  font-normal mb-4 flex text-right">Edit About
                <div class="mt-2 ml-2">
                    <BaseToolTip text="Edit company about here"> </BaseToolTip>
                </div>
            </div>
            <div class="w-full text-left grid grid-cols-2 gap-5 sentence-case">
                <LargeTextInput :counting="true" :value="updatedBio" @update:value="updatedBio = $event" rows="10" />

                <!-- <button @click="saveCompanyBio">Test</button> -->
                <SaveCancelForm class="col-span-2" @onSave="saveCompanyBio" @onCancel="$emit('onClose')"
                    justifyOrientation="end" :display="true"  />
            </div>
        </div>
    </modal>

</template>

<script>
import Modal from "../../../../widgets/Modal.vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import LargeTextInput from "@/components/inputs/LargeTextInput";
import BaseToolTip from "../../../../widgets/BaseToolTip.vue";
export default {
    name: "CompanyAboutEdit",
    components: {
        Modal,
        LargeTextInput,
        SaveCancelForm,
        BaseToolTip
    },
    props: {
        bio: {
            type: String,
        },
        editActive: {
            type: Boolean,
        }
    },
    data() {
        return {
            updatedBio: this.bio,
        };
    },
    methods: {
        async saveCompanyBio() {
            await this.$store.dispatch("editCompany", { bio: this.updatedBio  })
            await this.$store.dispatch("fetchCompany", this.$route.params.id)
            this.$emit("onClose")
        }
    }
}
</script>