<template>
    <modal :modalActive="editActive" :duration="400" version="2" @close="$emit('onClose')">

        <div class="overflow-x-hidden rounded-lg pt-8 pb-4 px-4 bg-white text-left dark:bg-primaryDark dark:text-white overflow-scroll lg:overflow-hidden  lg:overflow-auto scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded dark:scrollbar-track:!bg-slate-500/[0.16] dark:scrollbar-thumb:!bg-slate-500/50 max-h-100 md:max-h-full">
            <div class="text-2xl text-left ml-2 font-medium mb-2 flex">Edit Header
                <div class="mt-2 ml-2">
                    <BaseToolTip :text="tooltipText"> </BaseToolTip>
                </div>
            </div>
            <div><p class="text-xs ml-2">Here you can select what information to show in your's company header.</p></div>
            <div class="md:col-span-3 sm:col-span-4 sm:col-start-2 grid grid-cols-4 gap-2 dark:text-white">
                <div class="col-span-4 grid md:grid-cols-2">
                    <div v-if="addresses?.length > 0" class="p-2 md:p-4">
                        <Listbox v-model="selectedAddresses" multiple name="Address">
                            <ListboxLabel class="flex text-sm">Select Locations 
                                 <div class=" ml-2">
                                    <BaseToolTip text="Select Which addresses should display on company's header"> </BaseToolTip>
                                </div>
                            </ListboxLabel>
                            <div class="relative mt-1">
                                <ListboxButton
                                    :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton', listboxButtonClasses]">
                                    <span v-if="selectedAddresses.length > 0" class="block truncate">{{
                                            selectedAddresses.map((a) => a).join(', ')
                                    }}</span>
                                    <span v-else class="block truncate">Select Address</span>
                                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions
                                        :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions', listboxOptionsClasses]">
                                        <ListboxOption v-slot="{ active, selected }" v-for="address, idx in addresses"
                                            :key="idx" :value="address" as="template">
                                            <li :class="[
                                                active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-10 pr-4',
                                            ]">
                                                <span :class="[
                                                    selected ? 'font-medium' : 'font-normal',
                                                    'block truncate',
                                                ]">{{ address }}</span>
                                                <span v-if="selected"
                                                    :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                    </div>
                    <div v-if="phoneNumbers?.length > 0" class="p-2 md:p-4">
                        <Listbox v-model="selectedPhone">
                            <ListboxLabel class="flex text-sm">Select Primary Phone
                                <div class=" ml-2">
                                    <BaseToolTip text="Select Which Phone Number to display on company's header"> </BaseToolTip>
                                </div>
                            </ListboxLabel>
                            <div class="relative mt-1">
                                <ListboxButton
                                    :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton', listboxButtonClasses]">
                                    <span class="block truncate">{{ selectedPhone }}</span>
                                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions
                                        :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions', listboxOptionsClasses]">
                                        <ListboxOption v-slot="{ active, selected }" v-for="phone in phoneNumbers"
                                            :key="phone.id" :value="phone.phoneNumber" as="template">
                                            <li :class="[
                                                active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-10 pr-4',
                                            ]">
                                                <span :class="[
                                                    selected ? 'font-medium' : 'font-normal',
                                                    'block truncate',
                                                ]">{{ phone.phoneNumber }}</span>
                                                <span v-if="selected"
                                                    :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                    </div>
                    <div v-if="emails?.length > 0" class="p-2 md:p-4">
                        <Listbox v-model="selectedEmails">
                            <ListboxLabel class="flex text-sm">Select Primary Email
                                 <div class=" ml-2">
                                    <BaseToolTip text="Select Which email should display on company's header"> </BaseToolTip>
                                </div>
                            </ListboxLabel>
                            <div class="relative mt-1">
                                <ListboxButton
                                    :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton', listboxButtonClasses]">
                                    <span class="block truncate">{{ selectedEmails }}</span>
                                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions
                                        :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions', listboxOptionsClasses]">
                                        <ListboxOption v-slot="{ active, selected }" v-for="email in emails"
                                            :key="email.id" :value="email.email" as="template">
                                            <li :class="[
                                                active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-10 pr-4',
                                            ]">
                                                <span :class="[
                                                    selected ? 'font-medium' : 'font-normal',
                                                    'block truncate',
                                                ]">{{ email.email }}</span>
                                                <span v-if="selected"
                                                    :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                    </div>
                    <div v-if="websites?.length > 0" class="p-2 md:p-4">
                        <Listbox v-model="selectedWebsites">
                            <ListboxLabel class="flex text-sm ">Select Primary Websites
                                 <div class=" ml-2">
                                    <BaseToolTip text="Select Which website should display on company's header"> </BaseToolTip>
                                </div>
                            </ListboxLabel>
                            <div class="relative mt-1">
                                <ListboxButton
                                    :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton', listboxButtonClasses]">
                                    <span class="block truncate">{{ selectedWebsites }}</span>
                                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions
                                        :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions', listboxOptionsClasses]">
                                        <ListboxOption v-slot="{ active, selected }" v-for="website in websites"
                                            :key="website.id" :value="website.url" as="template">
                                            <li :class="[
                                                active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-10 pr-4',
                                            ]">
                                                <span :class="[
                                                    selected ? 'font-medium' : 'font-normal',
                                                    'block truncate',
                                                ]">{{ website.url }}</span>
                                                <span v-if="selected"
                                                    :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                    </div>
                    <div v-if="socials?.length > 0" class="p-2 md:p-4">
                        <Listbox v-model="selectedSocials" multiple >
                            <ListboxLabel class="flex text-sm flex-wrap">Select Social Media to display
                                 <div class=" ml-2">
                                    <BaseToolTip text="Select Which social media should display on company's header"> </BaseToolTip>
                                </div>
                            </ListboxLabel>
                            <div class="relative mt-1">
                                <ListboxButton
                                    :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton', listboxButtonClasses]">
                                    <span v-if="selectedSocials.length > 0" class="block truncate">{{
                                            selectedSocials.map((job) => job).join(', ')
                                    }}</span>
                                    <span v-else class="block truncate">Select Social Media</span>
                                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition duration-100 ease-in"
                                    leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions
                                        :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions', listboxOptionsClasses]">
                                        <ListboxOption v-slot="{ active, selected }" v-for="social, idx in socials"
                                            :key="idx" :value="social" as="template">
                                            <li :class="[
                                                active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-10 pr-4',
                                            ]">
                                                <span :class="[
                                                    selected ? 'font-medium' : 'font-normal',
                                                    'block truncate',
                                                ]">{{ social }}</span>
                                                <span v-if="selected"
                                                    :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']">
                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            </li>
                                        </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                    </div>
                    <div>
                        <p></p>
                    </div>
                </div>
                <SaveCancelForm class="col-span-2 col-start-3" :display="true" justifyOrientation="end"
                    @onSave="onSubmit" @onCancel="$emit('onClose')"  />
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '../../../widgets/Modal'
import SaveCancelForm from "@/components/forms/SaveCancelForm"
import BaseToolTip from '../../../widgets/BaseToolTip'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import capitalize from "../../../../utils/Capitalize";
export default {
    name: "CompanyHeaderEdit",
    components: {
        Modal, SaveCancelForm, BaseToolTip, Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption, CheckIcon, SelectorIcon,
    },
    props: {
        editActive: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            tooltipText: "Here you can select what information to show in your company’s header",
        }
    },
    computed: {
        listboxButtonClasses() {
            return "relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left border sm: text-sm";
        },
        listboxOptionsClasses() {
            return " absolute mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus: outline-none sm:text-sm z-50"
        }

    },
    methods: {
        sentenceCase(string) {
            //  Makes the first char of a string uppercase
            //  and the following chars lowercase
            return capitalize(string)
        },
    },
    setup(props, { emit }) {
        const store = useStore();
        const darkModeEnabled = ref(store.getters.getDarkMode)
        const submitActive = ref(false)

        // fetching selected values
        const selectedPhone = ref(store.getters.getSortedCompanyPhoneNumbers[0]?.phoneNumber)
        const selectedEmails = ref(store.getters.getSortedCompanyEmails[0]?.email)
        const selectedWebsites = ref(store.getters.getSortedCompanyWebsites[0]?.url)
        const selectedAddresses = ref(store.getters.getSelectedCompanyAddress.map(a => a.city))
        const selectedSocials = ref(store.getters.getSelectedCompanySocials.map(s => s.network))

        // fetching all entered values
        const allAddress = computed(() => store.getters.getCompanyHeader.addresses)
        const allSocials = computed(() => store.getters.getCompanyHeader.socials)

        const phoneNumbers = computed(() => store.getters.getSortedCompanyPhoneNumbers)
        const emails = computed(() => store.getters.getSortedCompanyEmails)
        const websites = computed(() => store.getters.getSortedCompanyWebsites)
        const addresses = computed(() => allAddress.value.map((a) => a.city))
        const socials = computed(() => allSocials.value.map(link => link.network))

        return {
            darkModeEnabled,
            submitActive,
            isLoading: computed(() => store.getters.isLoading),

            async onSubmit() {
                // console.log("i am in onSubmit")
                submitActive.value = true
                function delay(ms) {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }
                await delay(200);

                // Addresses
                let addressIds = allAddress.value.filter(address => selectedAddresses.value?.includes(address.city))
                addressIds = addressIds.map(address => address.id)
                if (addressIds.length > 0) await store.dispatch("setCompanyAddressPrimary", addressIds)

                // Phone
                let phoneIds = phoneNumbers.value.filter(phone => selectedPhone.value?.includes(phone.phoneNumber))
                phoneIds = phoneIds.map(phone => phone.id)
                // console.log("phone IDs: ", phoneIds)
                if (phoneIds.length > 0) await store.dispatch("setCompanyPhonePrimary", phoneIds)

                // Emails
                let emailIds = emails.value.filter(email => selectedEmails.value?.includes(email.email))
                emailIds = emailIds.map(email => email.id)
                // console.log("email IDs: ", emailIds)
                if (emailIds.length > 0) await store.dispatch("setCompanyEmailPrimary", emailIds)

                // Websites
                let websiteIds = websites.value.filter(website => selectedWebsites.value?.includes(website.url))
                websiteIds = websiteIds.map(website => website.id)
                // console.log("websites IDs: ", websiteIds)
                if (websiteIds.length > 0) await store.dispatch("setCompanyWebsitePrimary", websiteIds)

                // Socials
                let socialIds = allSocials.value.filter(social => selectedSocials.value?.includes(social.network))
                socialIds = socialIds.map(social => social.id)
                console.log("socials ids: ", socialIds)
                if (socialIds.length > 0) await store.dispatch("setCompanySocialPrimary", socialIds)

                await store.dispatch("fetchCompany", store.getters.getCompanyId)
                emit('onClose')

            },

            // selected company values
            selectedAddresses,
            selectedPhone,
            selectedEmails,
            selectedWebsites,
            selectedSocials,



            // Setters
            setAddresses: val => selectedAddresses.value = val.value,
            setPhone: val => selectedPhone.value = val.value,
            setEmails: val => selectedEmails.value = val.value,
            setWebsites: val => selectedWebsites.value = val.value,
            setSocials: val => selectedSocials.value = val.value,


            // getters
            phoneNumbers,
            emails,
            websites,
            addresses,
            socials
        }
    }
}
</script>