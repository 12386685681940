<template>
    <!-- IMAGE MODAL overflow-y-scroll overflow-hidden max-h-100 -->
  <Modal version="6" @close="$emit('close')" class="Profile_img" :modalActive="imagePoiSelectorOn">
      <div class=" bg-white rounded p-7 flex flex-col z-50 editCompanyModal dark:bg-primaryDark">
          <LoadingSpinner v-if="isLoading" />
          <div v-else>
              <!-- <h1 class="text-2xl font-semibold text-gray-700">Edit Company Picture</h1> -->
              <ImagePoiInput
                    @submit="submitPhoto"
                    @close="$emit('close')"
                />
              <div class="grid grid-cols-6 gap-4 mb-4" >
                <div class="relative main_block" v-for="img in companyPictures" :key="img.uuid" v-show="img.croppedImage">
                    <div  class="absolute hidden action_button right-0 p-0 cursor-pointer">
                        <div class="flex text-white dark:text-white ">
                        <TrashIcon @click="deleteEntry(img.uuid)" class="w-4 mt-1 mx-1" />
                    </div>
                    </div>
                <img 
                           
                    :src="img.croppedImage"
                    @click="selectPhotoAsLogo(img.uuid)"
                    class="col-span-1 cursor-pointer"
                    />
                    
              </div>
              </div>
              <!-- <button @click="$emit('close')" class="bg-gray-200 text-lg p-4">Close</button> -->
          </div>
      </div>
  </Modal>
</template>

<script>
/* eslint-disable no-unreachable */
import { useStore } from 'vuex'
import { reactive, ref, computed } from 'vue'
import ImagePoiInput from '@/components/inputs/ImageProfilePoiInput'
import Modal from '@/components/widgets/Modal'
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import { PhotoAlbumNames } from '@/constants'
import { TrashIcon } from "@heroicons/vue/outline/esm"
import { createToaster } from "@meforma/vue-toaster";

export default {
name: "CompanyPhotoEdit",
components: { ImagePoiInput, Modal, LoadingSpinner,TrashIcon },

setup(props,{emit}) {
const store = useStore()
const form = reactive({
    image: {
        preview: store.getters.getCompanyProfilePicture,
        image: null,
        coordinates: {
            width: 0,
            height: 0,
            left: 0,
            top: 0
        }
    },
    professionalName: store.getters.getUserDisplayProfessionalName,
    givenName: !store.getters.getUserDisplayProfessionalName
})
const submitActive = ref(false)
const fileToBig = ref(false)
const imagePoiSelectorOn = ref(false)
function closePhotoWidget() {
    form.image = {
        preview: store.getters.getCompanyProfilePicture,
        image: null,
        coordinates: {
            width: 0,
            height: 0,
            left: 0,
            top: 0
        }
    }
    imagePoiSelectorOn.value = false
    emit('close')
}

return {
    closePhotoWidget,
    store,
    form,
    submitActive,
    imagePoiSelectorOn,
    fileToBig,
    profilePicture: computed(() => store.getters.getCompanyProfilePicture),
    companyPictures: computed(() => store.getters.getCompanyAlbum),
    isLoading: computed(() => store.getters.isLoading),
    async submitPhoto({ file, crop, croppedFile }) {
        const toast = createToaster({position:"top-right"});
        if (file || croppedFile) {
            await store.dispatch("createCompanyMediaImage",
                 { file,
                   crop,
                   croppedFile,
                   logo: true,
                   companyId: store.getters.getCompanyId,
                   album: PhotoAlbumNames.ProfilePhotos
                })
                await store.dispatch("fetchCompany", store.getters.getCompanyId);
          closePhotoWidget()
          toast.success(`Profile picture has been uploaded successfully`);
        }

         
    },
    async selectPhotoAsLogo(uuid) {
        await store.dispatch("editCompanyMediaImage", { uuid, logo: true,companyId:store.getters.getCompanyId })
         imagePoiSelectorOn.value = false

    },async closePhoto(){
        
            closePhotoWidget()
    },
    async deleteEntry(uuid){
        const toast = createToaster({position:"top-right"});
        const result = confirm("Are you sure you want to delete?")
        try {
          
          if (uuid && result) {
            await store.dispatch("editCompanyMediaImage", { uuid,delete: true,logo:false})
              toast.success(`Profile picture has been deleted successfully`);
            }
            await store.dispatch("fetchCompany", store.getters.getCompanyId);
          
        } catch (e) {
          console.error(e)
        }
    }

}
}
}


</script>
