<template>
    <!-- IMAGE MODAL -->
    <Modal :modalActive="imagePoiSelectorOn" :duration="400" version="1" @close="$emit('close')">
        <div class="rounded p-2 flex flex-col z-50" >
            <LoadingSpinner v-if="isLoading" />
            <div v-else>
                <form ref="anyName" @submit.prevent="onSubmit">
                    <div
                        class="w-full md:w-12/12 my-232 2xl:overflow-hidden shadow py-4 sm:rounded-md overflow-hidden bg-white md:py-10 px-4 sm:p-6 dark:bg-primaryDark overflow-hidden max-h-100 2xl:max-h-full xl:overflow-y-unset overflow-y-scroll h-auto">
                        <div class="flex lg:w-full justify-around md:flex-row flex-col">
                            <div class="w-full md:w-5/12 md:px-2 md:pl-1">
                                <p class=" flex items-center dark:text-white mb-6">
                                    Add production <span class="px-2"></span>
                                </p>
                                <SmallTextInput heading="Name " :tooltip="true"
                                    toolText="Add name" class="col-span-6 " :value="form.name"
                                    @update:value="form.name = $event" :errors="v$.name.$errors" />
                                <p class="text-sm flex text-red items-center dark:text-white mt-4">
                                    <SelectInput heading="Type" :tooltip="true" 
                                        toolText="Select type" class="col-span-6 sm:col-span-3 w-full gap-1"
                                        :options="companyTypes" :value="form.companyType"
                                        @update:value="form.companyType = $event" :errors="v$.companyType.$errors" />
                                </p>

                            </div>
                            <div class="w-full md:w-5/12 py-2">
                                <input type="file" class="hidden" @change="onFileUpload($event.target.files)"
                                    ref="fileInput" />
                                <div v-show="this.logoPreview == ''" @click="$refs.fileInput.click()"
                                    class=" relative bg-gray-200 width h-44 md:h-full md:w-36 flex items-center justify-center overflow-visible m-auto">
                                    <p class=" inset-0 z-10 text-center flex items-center"> Add
                                        picture <BaseToolTip text="Add picture"> </BaseToolTip>
                                    </p>
                                </div>
                                <div v-show="this.logoPreview != ''" @click="$refs.fileInput.click()"
                                    class=" relative bg-gray-200 width h-44 md:h-full md:w-36 flex items-center justify-center overflow-visible m-auto">
                                    <img class="h-full w-full absolute object-cover" :src="logoPreview"
                                        alt="Header image of Shakespere on Ice"> -->

                                </div>
                            </div>
                        </div>
                        <div class="flex lg:w-full justify-around md:flex-row flex-col">
                            <div class="w-full md:w-5/12  mt-4 ">
                                <SmallTextInput heading="Director " :tooltip="true"
                                    toolText="Add Director" class="col-span-6 md:pl-1 md:pr-2 capitalize"
                                    :value="form.director" @update:value="form.director = $event" />
                            </div>

                            <div class="w-full md:w-5/12  mt-4">
                                <SmallTextInput heading="Author " :tooltip="true"
                                    toolText="Add Author" class="col-span-6 " :value="form.role"
                                    @update:value="form.role = $event" />
                            </div>

                        </div>
                        <div class="flex justify-around lg:w-full md:flex-row flex-col">
                            <div class="w-full md:w-5/12  mt-4 ">
                                <SmallTextInput heading="Based on " :tooltip="true" toolText="Add Based on"
                                    class="col-span-6 md:pl-1 md:pr-2" :value="form.based_on"
                                    @update:value="form.based_on = $event" />

                            </div>
                            <div class="w-full md:w-5/12  mt-4">
                                <SmallTextInput heading="Venue " :tooltip="true" toolText="Add Venue" class="col-span-6"
                                    :value="form.venue" @update:value="form.venue = $event" />
                            </div>
                        </div>
                        <div class="flex justify-around lg:w-full md:flex-row flex-col">
                            <div class="w-full md:w-5/12  mt-4 md:px-2 md:pl-1">
                                <DateInput class="col-span-3"  heading="Start date"
                                    :tooltip="true" toolText="Add Start date" />


                            </div>
                            <div class="w-full md:w-5/12  mt-4 md:pl-1">
                                <SmallTextInput heading="Space " :tooltip="true" toolText="Add Space" class="col-span-6"
                                    :value="form.space" @update:value="form.space = $event" />
                            </div>
                        </div>
                        <div class="flex justify-around lg:w-full md:flex-row flex-col">
                            <div class="w-full md:w-5/12 md:px-2  mt-4 md:pl-1">
                                <DateInput class="col-span-3" heading="End date" :tooltip="true" toolText="Add End date"
                                    :value="form.endDate" @update:value="form.endDate = $event" />
                            </div>
                            <div class="w-full md:w-5/12  mt-4   md:px-2 md:pl-1">
                            </div>
                        </div>
                        <div class="py-3 mt-2 bg-white text-right  dark:text-white dark:bg-primaryDark">
                            <!-- <button @click="saveCompanyBio">Test</button> -->
                            <SaveCancelForm class="col-span-2" @onCancel="$emit('close')" @onSave="onSubmit"
                                justifyOrientation="end" :display="true" :transparentButtons="true" />
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </Modal>
</template>
<script>
/* eslint-disable no-unreachable */
import { useStore } from 'vuex'
import { computed, reactive, ref } from 'vue'
import Modal from '@/components/widgets/Modal'
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import SmallTextInput from "@/components/inputs/SmallTextInput";
import SelectInput from "@/components/inputs/SelectInput";
import DateInput from "@/components/inputs/DateInput";
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { notify } from "@kyvg/vue3-notification";

export default {
    name: "CompanyProductionModal",
    components: { Modal, LoadingSpinner, SmallTextInput, SelectInput, DateInput, BaseToolTip, SaveCancelForm },
    props: {
        imagePoiSelectorOn: {
            type: Object,
        }
    },
    async Mounted() {
        await this.$store.dispatch('fetchProductions', { first: 12, after: "", before: "", searchFilter: "", sortBy: "" })
        await this.$store.dispatch('dynamicTableLoadProductions')
    },
    setup(props, { emit }) {
        const store = useStore()
        store.dispatch("fetchCompanyTypes");
        store.dispatch("fetchWorkTypes")
        const form = reactive({
            name: null,
            companyType: null,
            logo: null,
            director: null,
            role: null,
            based_on: null,
            venue: null,
            startDate: '',
            space: null,
            endDate: ''

        })
        const rules = {
            name: { required },
            companyType: { required },
            director: { required },
            role: { required },
            venue: { required },


        };
        function closePhotoWidget() {

            emit('close')
        }
        const logoPreview = ref('')
        const v$ = useVuelidate(rules, form, { $lazy: true });
        return {
            companyTypes: computed(() => store.getters.getWorkTypes),
            showModal: false,
            form,
            v$,
            store,
            logoPreview,
            isLoading: computed(() => store.getters.isLoading),
            onFileUpload(files) {
                this.logoPreview = URL.createObjectURL(files[0])

            },
            async onSubmit() {
                if (v$.$error) {
                    // window.scrollTo(0, this.$refs.top.offsetTop)
                    notify({
                        title: "Something went wrong",
                        text: "Some required fields were not provided",
                        type: 'warn',
                        duration: 2000
                    })
                    return
                }
                await store.dispatch('createProduction', form)
                form.name = null
                form.companyType = null
                form.logo = null
                form.director = null
                form.role = null
                form.based_on = null
                form.venue = null
                form.startDate = ''
                form.space = null
                form.endDate = ''

                emit('close')

            },
            async closePhoto() {
                closePhotoWidget()
            }

        }

    },

}
</script>