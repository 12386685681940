<template>
  <div>
    <div class="flex">
      <!-- DESKTOP VIEW -->
      <div id="" class="hidden w-full lg:grid grid-cols-9 lg:grid-cols-10  dark:bg-primaryDark">
         <div
          class="h-28 w-28 lg:h-36 lg:mr-4 lg:w-36 lg:justify-self-end place-self-center col-start-5 lg:col-start-2 col-span-1 overflow-hidden flex justify-center">
          <img v-if="editMode"  @click ="imagePoiSelectorOn=!imagePoiSelectorOn"   :src="(profilePicture ? profilePicture :headerInfo.logo)" class="rounded-full w-full object-cover" :class="(editMode==true)?'cursor-pointer':''" />
          <img v-else @click ="displayProfilePicturesOn=!displayProfilePicturesOn" :src="profilePicture ? profilePicture :headerInfo.logo" class="rounded-full w-full object-cover" />
        </div>
        <div class="col-span-7 lg:col-start-3 col-start-2 dark:text-gray-300 ">
          <div class="flex flex-row items-baseline lg:justify-start justify-center ">
            <h2 class="mt-1 lg:mt-0 font-25 font-bold">
              {{ headerInfo.title }}
            </h2>
          </div>
          <div class="flex lg:justify-between border-b border-gray-400 pt-2 pb-1 justify-center">
            <div class="flex flex-wrap lg:gap-3">
              <div class="capitalize small-caps text-base text-gray-600  dark:text-white ">
                <h3>{{ headerInfo.subTitle }}</h3>
                <h3 v-if="headerInfo.subTitle2">
                  ,
                  {{ headerInfo.subTitle2 }}
                </h3>
              </div>
              <div class="flex flex-row md:text-base text-sm" v-if="headerInfo.addresses">
                <div v-for="loc, idx in headerInfo.addresses" :key="idx"
                  class="flex  text-gray-500 dark:text-gray-400 self-center">
                  <p v-if="loc.isPrimary">
                    <font-awesome-icon icon="fa-solid fa-location-dot" class="self-center text-gray-400 dark:text-white" />
                    {{ sentenceCase(loc.city) }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="editMode" @click="editActive = true"
              class="cursor-pointer flex dark:text-gray-400 text-gray-500">
              <PencilIcon  class="w-4 mt-1 dark:text-white" />
             
            </div>
          </div>
          <div class="flex lg:justify-between lg:flex-row flex-col items-center  pt-1 pb-3 flex-wrap">
            <div class="gap-2 flex flex-wrap lg:justify-start justify-center">
              <!-- Phones -->
              <div class="flex font-13  flex-row">
                <div class="" v-for="phone, idx in headerInfo.phoneNumbers" :key="idx">
                  <div class="flex flex-row" v-if="phone?.isPrimary">
                    <font-awesome-icon fixed-width icon="fa-solid fa-phone"
                      class="self-center mr-2 font-13 text-gray-400 text-gray-400 dark:text-white" />
                    <a :href="`tel:${phone?.phoneNumber}`">
                      <p class="mr-2 font-normal text-justify">{{ phone?.phoneNumber }}</p>
                    </a>
                  </div>
                </div>
                <Menu v-if="headerInfo.phoneNumbers?.length > 1" as="div"
                  class="self-center relative inline-block text-left" v-slot="{ open }">
                  <div>
                    <MenuButton class="">
                      <font-awesome-icon v-if=(!open) icon="fa-solid fa-chevron-down"
                        class="pr-2 self-center text-center font-13 text-gray-400 dark:text-white" aria-hidden="true" />
                      <font-awesome-icon v-else icon="fa-solid fa-chevron-up font-13" class="pr-2 font-13" aria-hidden="true" />
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems
                      class="z-20 origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-primary dark:bg-primaryDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem v-for="phone, idx in headerInfo.phoneNumbers" :key="idx" v-slot="{ active }">
                      <div class='block text-sm'>
                        <a :href="`tel:${phone?.phoneNumber}`"
                          :class="[active ? 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-50 rounded-md' : 'text-gray-700 dark:text-gray-200', 'block px-2 py-2 text-sm']">
                          <div class="flex flex-col whitespace-nowrap">
                            <p class="flex flex-row text-gray-600 dark:text-gray-400 text-xs">
                              {{ phone?.phoneNumber }}
                            </p>
                            <p class="flex flex-row">
                              {{ phone?.phoneNumber }}
                            </p>
                          </div>
                        </a>
                      </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
              <!-- Emails -->
           
              <div class="flex flex-row font-13">
                <div class="" v-for="email, idx in headerInfo.emails" :key="idx">
                  <div class="flex flex-row" v-if="email?.isPrimary">
                    <font-awesome-icon fixed-width icon="fa-solid fa-envelope"
                      class="self-center mr-2 text-gray-400 dark:text-white" />
                     
                    <a :href="'mailto:' + email?.email" class="mr-2 font-normal"> {{ email?.email }}</a>
                  </div>
                </div>
                <Menu v-if="headerInfo.emails?.length > 1" as="div" class="self-center relative inline-block text-left"
                  v-slot="{ open }">
                  <div>
                    <MenuButton class="">
                      <font-awesome-icon v-if=(!open) icon="fa-solid fa-chevron-down"
                        class="pr-2 self-center text-center font-13 text-gray-400 dark:text-white" aria-hidden="true" />
                      <font-awesome-icon v-else icon="fa-solid fa-chevron-up font-13" class="pr-2 font-13" aria-hidden="true" />
                    </MenuButton>
                  </div>
                 
                  <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems
                      class="z-20 origin-top-right absolute right-0 mt-2 w-auto max-w-56 rounded-md shadow-lg bg-primary dark:bg-primaryDark ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem v-for="email, idx in headerInfo.emails" :key="idx" v-slot="{ active }">
                      <div class='block text-sm'>
                 
                        <a :href="'mailto:' + email?.email"
                          :class="[active ? 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-50 rounded-md' : 'text-gray-700 dark:text-gray-200', 'block px-2 py-2 text-sm']">
                          <div class="flex flex-col whitespace-nowrap">
                            <p class="flex flex-row text-gray-600 dark:text-gray-400 text-xs">
                              <!-- TODO:  Decide if we want to show something if there is no label -->
                              <!-- {{ email.label ? email.label : '\xa0' }} -->
                              {{ email?.email }}
                            </p>
                            <p class="flex flex-row">
                              {{ email?.email }} s
                            </p>
                          </div>
                        </a>
                      </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
              <!-- Links -->
              <div class="flex flex-row font-13">
                <div class="" v-for="link, idx in headerInfo.websites" :key="idx">
                  <div class="flex flex-row" v-if="link?.isPrimary">
                    <font-awesome-icon fixed-width icon="fa-solid fa-link"
                      class="self-center mr-2 text-gray-400 text-gray-400 dark:text-white font-13" />
                    <!-- If link has no title, show URL instead -->
                    <p v-if=(link.title) @click="openLink(link)" class="mr-2 cursor-pointer ">{{ link.url }}
                    </p>
                    <p v-else @click="openLink(link)" class="mr-2 font-normal cursor-pointer ">{{ link.url }}</p>
                  </div>
                </div>
                <Menu v-if="headerInfo.websites?.length > 1" as="div"
                  class="self-center relative inline-block text-left" v-slot="{ open }">
                  <div>
                    <MenuButton class="">
                      <font-awesome-icon v-if=(!open) icon="fa-solid fa-chevron-down"
                        class="pr-2 self-center text-center text-gray-400 dark:text-white" aria-hidden="true" />
                      <font-awesome-icon v-else icon="fa-solid fa-chevron-up font-13" class="pr-2" aria-hidden="true" />
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems
                      class="z-20 origin-top-right absolute right-0 mt-2 w-auto max-w-56 rounded-md shadow-lg bg-primary dark:bg-primaryDark ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                      <MenuItem v-for="link, idx in headerInfo.websites" :key="idx" v-slot="{ active }">
                      <div class='block text-sm'>
                        <a @click="openLink(link)"
                          :class="[active ? 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-50 rounded-md' : 'text-gray-700 dark:text-gray-200', 'block px-2 py-2 text-sm']">
                          <div class="flex flex-col whitespace-nowrap">
                            <p class="flex flex-row text-gray-600 dark:text-gray-400 text-xs">
                              <!-- TODO:  Decide if we want to show something if there is no label -->
                              <!-- {{ email.label ? email.label : '\xa0' }} -->
                              {{ link?.url }} 
                            </p>
                            <p class="flex flex-row">
                              {{ link?.url }}
                            </p>
                          </div>
                        </a>
                      </div>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
       
            <div class="flex h-6  lg:justify-start justify-center ">
              <div v-for="social, idx in headerInfo.socials" :key="idx" @click="openLink(social)"
                data-bs-toggle="tooltip" data-bs-placement="top" :title="social.username">
                <div v-if="social.isPrimary">
                <!-- :alt="social.username" -->
                  <img  class="h-full  cursor-pointer w-5 lg:mx-0 mx-2"
                  :src="[darkModeOn ? social.wLogo : social.logo]" />
                  <!-- <font-awesome-icon icon="fa-solid fa-location-dot"
                    class="cursor-pointer self-center mr-2 text-gray-400" /> -->
                </div>
              </div>
              <!-- <VCard v-if="enableVcard" class="h-full lg:mx-1 mx-2" /> -->
            </div>
          </div>
        </div>
        <slot></slot>
      </div>

      <!-- MOBILE VIEW -->
      <div id="" class="lg:hidden shadow w-full pb-8 lg:pb-0 grid grid-cols-9 dark:bg-primaryDark">
        <div
          class="h-28 w-28 lg:h-36 lg:mr-4 lg:w-36 lg:justify-self-end place-self-center col-start-5 lg:col-start-2 col-span-1 overflow-hidden flex justify-center">
          <img v-if="editMode"  @click ="imagePoiSelectorOn=!imagePoiSelectorOn"  :src="headerInfo.logo ? headerInfo.logo : ''" class="rounded-full w-full object-cover" />
        </div>
        <div class="col-span-9 col-start-1 dark:text-gray-300 grid grid-cols-3 lg:grid-cols-none">
          <div class="col-span-3 place-self-center flex items-baseline lg:justify-start justify-center">
            <h2 v-if="headerInfo && headerInfo.title" class="font-25 font-bold mt-1 lg:mt-0">{{ headerInfo.title }}
            </h2>
          </div>
          <div class="col-span-3 flex lg:justify-between  pb-1 justify-center">
            <div class="grid-flow-row justify-center ">
              <div class="flex flex-row">
                <p v-if="headerInfo.subTitle"
                  class="capitalize small-caps py-2 mx-2 text-sm  text-gray-600  dark:text-white align">
                  {{ headerInfo.subTitle }}</p>
                <p v-if="headerInfo.subTitle2"
                  class="capitalize small-caps py-2 mx-2 text-sm  text-gray-600  dark:text-white align">
                  ,
                  {{ headerInfo.subTitle2 }}</p>
              </div>
              <div class="flex flex-row md:text-base text-sm justify-center pt-1">
                <p v-if="headerInfo.location" class="flex pr-3 text-gray-500 self-center">
                  <font-awesome-icon icon="fa-solid fa-location-dot"
                    class="self-center mr-2 text-gray-400 dark:text-gray-600" />
                  {{ sentenceCase(headerInfo.location) }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-3 border-b border-gray-400 w-4/5 place-self-center"> </div>
          <div class="col-span-3 flex lg:justify-between flex-col  pt-1 ">
            <div class=" flex flex-row justify-center text-xs my-2 mx-2">
              <div class="flex flex-row px-4" v-if="headerInfo.phone">
                <font-awesome-icon @click="phoneSheetActive = !phoneSheetActive" icon="fa-solid fa-phone" size="2xl"
                  class="svg-inline--fa fa-link fa-2xl w-4 h-4 lg:w-6 lg:h-6 self-center text-gray-800  dark:text-gray-400 border-2 border-gray-500 dark:border-gray-200 rounded-full p-3 cursor-pointer hover:bg-gray-300 dark-hover:bg-gray-800"
                  :class="phoneSheetActive ? 'bg-gray-500 dark:bg-gray-700' : ''" />
                <!-- <p class="text-justify self-center line-clamp-1">{{}}</p> -->
              </div>
              <div class="flex flex-row px-4" v-if="headerInfo.email">
                <font-awesome-icon @click="emailSheetActive = !emailSheetActive" icon="fa-solid fa-envelope" size="2xl"
                  class="svg-inline--fa fa-link fa-2xl w-4 h-4 lg:w-6 lg:h-6 self-center text-gray-800  dark:text-gray-400 border-2 border-gray-500 dark:border-gray-200 rounded-full p-3 cursor-pointer hover:bg-gray-300 dark-hover:bg-gray-800"
                  :class="emailSheetActive ? 'bg-gray-500 dark:bg-gray-700' : ''" />
                <a :href="'mailto:' + headerInfo.email" class="text-justify self-center line-clamp-1">{{}}</a>
              </div>
              <div class="flex flex-row px-4" v-if="headerInfo.website">
                <font-awesome-icon @click="linkSheetActive = !linkSheetActive" icon="fa-solid fa-link" size="2xl"
                  class="svg-inline--fa fa-link fa-2xl w-4 h-4 lg:w-6 lg:h-6 self-center text-gray-800  dark:text-gray-400 border-2 border-gray-500 dark:border-gray-200 rounded-full p-3 cursor-pointer hover:bg-gray-300 dark-hover:bg-gray-800"
                  :class="linkSheetActive ? 'bg-gray-500 dark:bg-gray-700' : ''" />
                <p @click="openLink(headerInfo.website)" class="cursor-pointer text-justify self-center line-clamp-1">
                  {{}}</p>
              </div>
            </div>

            <!-- socal medias -->
            <div class="col-span-3 pt-2 grid grid-flow-col-dense h-6  self-center item-center">
              <div v-for="social, idx in headerInfo.socials" :key="idx" @click="openLink(social)"
                data-bs-toggle="tooltip" data-bs-placement="top" :title="social.username">
                <!-- :alt="social.username" -->
                <div v-if="social.isPrimary">
                  <img class="h-full w-5 lg:mx-0 mx-2 cursor-pointer"
                    :src="[darkModeOn ? social.wLogo : social.logo]" />
                </div>
              </div>
            </div>

            <div v-if="editMode" class="col-span-3 pt-4 flex place-self-center dark:text-gray-400 text-gray-500">
              <font-awesome-icon icon="fa-solid fa-pencil" class="self-center mr-2 text-gray-400" />
              <button @click="editActive = true">Edit header</button>
            </div>
          </div>

        </div>
        <slot></slot>
      </div>
    </div>

    <!-- Phone Contact Sheet -->
    <BottomSheet :active="phoneSheetActive" @close="phoneSheetActive = false">
      <div>
        <div class="max-h-36 overflow-y-auto">
          <h1 class="text-xl pb-2">Phone</h1>
          <div v-for="phone, idx in headerInfo.phoneNumbers" :key="idx" class="flex flex-col justify-center text-lg"
            :class="headerInfo.phoneNumbers?.length - 1 == idx ? '' : 'border-b '">
            <div class="flex flex-row justify-center text-sm text-gray-500 dark:text-gray-300">
              <p class="flex flex-row">{{ phone?.label }} </p>
            </div>
            <p class="flex flex-row line-clamp-1">
              <a :href="`tel:${phone?.phoneNumber}`">
                {{ phone?.phoneNumber }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </BottomSheet>
    <!-- Email Contact Sheet -->
    <BottomSheet :active="emailSheetActive" @close="emailSheetActive = false">
      <div class="max-h-36 overflow-y-auto">
        <h1 class="text-xl pb-2">Email</h1>
        <div class="">
          <div v-for="email, idx in headerInfo.emails" :key="idx" class="flex flex-col justify-center text-lg h-12"
            :class="headerInfo.emails?.length - 1 == idx ? '' : 'border-b '">
            <div class="flex flex-row justify-center text-sm text-gray-500 dark:text-gray-300">
              <p class="flex flex-row">{{ email?.label }} </p>
            </div>
            <p class="flex flex-row line-clamp-1">
              <a :href="`mailto:${email?.email}`">
                {{ email?.email }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </BottomSheet>
    <!-- Link Contact Sheet -->
    <BottomSheet :active="linkSheetActive" @close="linkSheetActive = false">
      <div class="max-h-36 overflow-y-auto">
        <h1 class="text-xl pb-2">Links</h1>
        <div class="">
          <div v-for="link, idx in headerInfo.websites" :key="idx" class="flex flex-col justify-center text-lg h-12"
            :class="headerInfo.websites?.length - 1 == idx ? '' : 'border-b '">
            <div class="flex flex-row justify-center text-sm text-gray-500 dark:text-gray-300">
              <p class="flex flex-row">{{ link?.title }} </p>
            </div>
            <p @click="openLink(link)" class="flex flex-row line-clamp-1">
              {{ link?.url }}
            </p>
          </div>
        </div>
      </div>
    </BottomSheet>

    <!-- Header edit state -->
    <CompanyHeaderEdit v-if="editActive" :editActive="editActive" @onClose="editActive = false"></CompanyHeaderEdit>

    <CompanyPhotoEdit :modalActive="imagePoiSelectorOn" 
      :imagePoiSelectorOn="imagePoiSelectorOn" @close="setIsPhotoEditOpen(false)"></CompanyPhotoEdit>

  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import capitalize from "../../../../utils/Capitalize";
import CompanyHeaderEdit from "./CompanyHeaderEdit";
// import { useRoute } from "vue-router";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import BottomSheet from "../../../widgets/bottomSheet/BottomSheet";
import { PencilIcon} from "@heroicons/vue/outline/esm"
import CompanyPhotoEdit from "@/components/companies/components/companyHeader/CompanyPhotoEdit";

export default {
  name: "CompanyHeader",
  components: { CompanyHeaderEdit, BottomSheet, Menu, MenuButton, MenuItem, MenuItems,PencilIcon,CompanyPhotoEdit },
  props: {
    headerInfo: {
      type: Object,
      required: true,
    },
    editMode: Boolean,
  },
  methods: {
    sentenceCase(string) {
      return capitalize(string);
    },
  },
  setup() {
    const store = useStore();
    const editActive = ref(false)
    const phoneSheetActive = ref(false);
    const emailSheetActive = ref(false);
    const linkSheetActive = ref(false);
    const imagePoiSelectorOn = ref(false)
    const displayProfilePicturesOn = ref(false);
    return {
      editActive,
      phoneSheetActive,
      emailSheetActive,
      linkSheetActive,
      imagePoiSelectorOn,
      displayProfilePicturesOn,
      profilePicture: computed(() => store.getters.getCompanyProfilePicture),
      setIsPhotoEditOpen(value) {
        imagePoiSelectorOn.value = value
      },
      darkModeOn: computed(() => store.getters.getDarkMode),
      openLink(href) {
        console.log("openLink. href:", href)
        if (href.url) {
          if (href.url.includes("https") || href.url.includes("http")) {
            window.open(href.url);
          } else {
            href.network ?? window.open("//" + href.url)
            window.open("https://" + href.network + '.com/' + href.url);
          }
        } else {
          if (href.includes("https") || href.url.includes("http")) {
            window.open(href);
          } else {
            window.open("//" + href);
          }
        }
      },
      email: computed(() => store.getters.getCompanyEmail),
    };
  },
};
</script>
