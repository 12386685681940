<template>
   <div class="mt-5">
        <div class="max-w-5xl pb-2 mx-auto rounded-md bg-white dark:bg-primaryDark dark:text-white">

            <div class="cursor-pointer inline-block" @click="router.push({ name: title })">
                <div class="pt-5 pl-5 pr-5 items-center flex gap-2 text-sm font-medium dark:text-white">
                    <svg width="16" height="14" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current text-gray-400 dark:text-white">
                        <path
                            d="M15.5835 16.3397H20.1668V14.5064C20.1668 12.9876 18.9356 11.7564 17.4168 11.7564C16.5409 11.7564 15.7605 12.1659 15.2569 12.804M15.5835 16.3397H6.41683M15.5835 16.3397V14.5064C15.5835 13.9048 15.4676 13.3303 15.2569 12.804M6.41683 16.3397H1.8335V14.5064C1.8335 12.9876 3.06471 11.7564 4.5835 11.7564C5.45947 11.7564 6.23978 12.1659 6.74339 12.804M6.41683 16.3397V14.5064C6.41683 13.9048 6.53272 13.3303 6.74339 12.804M6.74339 12.804C7.41921 11.1156 9.07041 9.92302 11.0002 9.92302C12.9299 9.92302 14.5811 11.1156 15.2569 12.804M13.7502 4.42302C13.7502 5.9418 12.5189 7.17302 11.0002 7.17302C9.48138 7.17302 8.25016 5.9418 8.25016 4.42302C8.25016 2.90424 9.48138 1.67302 11.0002 1.67302C12.5189 1.67302 13.7502 2.90424 13.7502 4.42302ZM19.2502 7.17302C19.2502 8.18554 18.4294 9.00635 17.4168 9.00635C16.4043 9.00635 15.5835 8.18554 15.5835 7.17302C15.5835 6.1605 16.4043 5.33969 17.4168 5.33969C18.4294 5.33969 19.2502 6.1605 19.2502 7.17302ZM6.41683 7.17302C6.41683 8.18554 5.59602 9.00635 4.5835 9.00635C3.57097 9.00635 2.75016 8.18554 2.75016 7.17302C2.75016 6.1605 3.57097 5.33969 4.5835 5.33969C5.59602 5.33969 6.41683 6.1605 6.41683 7.17302Z"
                            stroke-width="2" stroke="currentColor"></path>
                    </svg>People
                </div>
                <div class="flex flex-row items-center pl-2">
                    <div class="flex items-center justify-center">
                        <slot></slot>
                    </div>
                    <h2 class="text-xl m-2">{{ title }}</h2>
                
                </div>
                <div></div>
            </div>
            <div class="inline-block custom-min-height w-full relative img-width">
                <div v-if="previewItems.length === 0"
                    class="text-xl absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <h2 class="text-center">No Entries Found</h2>
                </div>
                <div v-else
                    class="grid grid-flow-row sm:grid-rows-1 sm:grid-cols-4 md:grid-cols-5 flex-wrap img-setting">

                    <div @click="previewItem.go" v-for="previewItem, idx in filteredPreviewItems" :key="idx"
                        class="block text-center	 items-center w-2/4 sm:w-1/4 xs:block cursor-pointer p-4 sm:py-2 sm:px-0 hover:bg-gray-100 dark-hover:bg-gray-800 rounded-lg ">
                        <div class="inline-block pr-4 w-24 sm:w-full sm:pt-1 sm:pb-1 sm:px-8 ">
                            <img :src="previewItem.image" class="w-full h-auto aspect-square object-cover rounded-full">
                        </div>
                        <div class="inline-block w-full">
                            <h2 class="block w-min-full px-2 text-sm  line-clamp-2 font-medium sm:text-center">{{
                                    previewItem.title
                            }}</h2>
                            <h3 class="block w-min-full px-2  text-sm  italic line-clamp-1 text-gray-400 sm:text-center">{{
                                    previewItem.subtitle
                            }}</h3>
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex pr-8 justify-end w-full mb-5">
                <button @click="this.$props.navigation.[2].onClick(this.$props.navigation.[2]); this.$props.changeComponent(this.$props.navigation.[2].component)"
                    class="float-right inline-flex items-center px-2.5 grey-slate py-1.5  shadow-sm text-sm font-medium rounded text-gray-600 underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white">
                    See more
                </button>
                
            </div>
        </div>

    </div>
   
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

const screenWidth = ref(0)
export default {
    name: 'PreviewRow',
     props: {
        title: String,
        previewItems: Array,
        changeComponent:Function,
        navigation: {
          type: Array
        },
    },

    setup(props) {
        const router = useRouter()
        return {
            router,
            screenWidth,
            filteredPreviewItems: computed(() => {
                // Skip everything past the 4th element if the screen size is between 640 and 768,
                // otherwise skip everything after the 5th element
                const isMiddleWidth = 640 <= screenWidth.value && screenWidth.value <= 768
                return props.previewItems.slice(0, isMiddleWidth ? 4 : 5)
            }),
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            screenWidth.value = window.innerWidth
        },
    }
}
</script>
<style>
.img-width img {
    width: 110px;
    height: 110px;
    margin: auto;
    text-align: center;
}

.img-width>div {
   
    display: flex;
    padding: 0 30px;
}

.img-width>div>div>div {
    padding: 0px 0px;
}

.img-width .flex.items-center.sm\:block.cursor-pointer.p-4.sm\:py-2.sm\:px-0.hover\:bg-gray-100.dark-hover\:bg-gray-800.rounded-lg {
    width: 25%;
}

@media (max-width:1200px) {
    .img-width>div {
        gap: 4px;
    }
}

@media (min-width: 767px) and (max-width: 900px) {

    .img-width img {
        width: 90px;
        height: 90px;
        margin: auto;
        text-align: center;
    }
}

@media (max-width: 645px) {
    .img-width>div {
        gap: 0px;
    }

    .img-width img {
        width: 100px;
        height: 100px;
        margin: auto;
        text-align: center;
    }
}
</style>
