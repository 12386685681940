<template>
 <div class="w-full flex items-start pb-6">
    <div class="flex flex-col w-full w-8/12 ">
  
      <div class="flex flex-col" v-for="(social, idx) in state.socials" :key="social.id">
        <div class="flex items-end sm:items-center ddd">
          <SocialsInput @change="checkSocial(social.network,social.id)" @update:username="(val) => editSocial(val, idx, 'username')"
            @update:network="(val) => editSocial(val, idx, 'network')" :social="social" socialWidth="w-full md:w-40 " />
            <div class="flex order-last">
              <TrashIcon v-if="state.socials.length > 1" @click="deleteSocial(idx)"
                class="w-6 ml-2 cursor-pointer text-gray-500" />
            </div>
        </div>
        <p class="text-sm p-2 text-gray-500" v-if="social.network === 'Snapchat' || social.network === 'Skype'">
          Please note: Due to technical reasons the {{social.network}}
          icon on your Showdeck profile will not link to your
          {{social.network}} profile, instead your username will appear as
          you hover over the icon. Your Snapchat username will
          still be able to appear with your contact information and vCard as applicable.
        </p>
      </div>
      <p @click="addSocial"  class="cursor-pointer grey-slate-text ">+ Add new line</p>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, reactive } from "vue";
import { TrashIcon } from '@heroicons/vue/outline/esm'
import SocialsInput from "@/components/inputs/SocialsInput";
import { createToaster } from "@meforma/vue-toaster";
export default {
  name: "CompanySocialForm",
  components: { SocialsInput, TrashIcon },
  setup() {
    const store = useStore()
    const state = reactive({
      socials: computed(() => store.getters.getCompanySocials)
    })
    if (state.socials.length === 0) store.commit('addNewCompanySocial')
    return {
      state,
      socialLink:computed(() => store.getters.getCompanySocials),
      socialTypes: computed(() => store.getters.getSocialTypes),
      addSocial() {
        store.commit('addNewCompanySocial')
      },
      editSocial(value, idx, key) {
        store.commit('editCompanySocialByIdx', { value, key, idx })
      },
      async deleteSocial(idx) {
        try {
          const social = store.getters.getCompanySocialByIdx(idx)
          if (social.id) {
            await store.dispatch('deleteCompanySocial', social.id)
          }
          store.commit('removeCompanySocialByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },
    }
  },
  methods: {
    checkSocial(val,id){
      const toast = createToaster({position:"top-right"});
     
      let socials=this.socialLink;
      let checkCurrentVal=socials.filter(x=>x.network === val);

      if(checkCurrentVal.length !==1 && id !== undefined || checkCurrentVal.length !==1 && id === undefined)
      toast.error(`This social media already exists`);
      return false
     
    }
  },
}
</script>