<template>
  <div class="w-full text-center">
    <div
      v-if="isMobile"
      class="pl-5 pr-5 bg-white overflow-hidden w-full grid col-span-10 lg:hidden profile-menu dark:bg-primaryDark"
    >
      <ul
        class="no-scroll-bar overflow-scroll font-medium flex md:flex-row md:space-x-8 md:mt-0 md:border-0"
      >
        <li v-for="items in navigation" :key="items.name">
          <button
            :class="[fetchButtonClass(items),(items.name=='Professional'?'hidden':'')]"
            @click="items.onClick(items);this.$emit('change', items.component);"
            class="block py-2 pl-3 pr-4 text-gray-900 dark:text-white"
            aria-current="page">
            {{ items.name }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
export default {
  name: "ProfileSliderMenu",
  props: {
    navigation: {
      type: Array,
    },
    noHide: {
      type: Boolean,
      default: false,
    },
    subNav: {
      // Is the navbar nested within another navbar?
      type: Boolean,
      default: false,
    },
    version: {
      required: false,
      default: "1",
    },
    isIconVisible: {
      required: false,
      default: false,
    },
  },
  // methods()
  setup(props) {
    const open = ref(false);
    const { width } = useBreakpoints();

    return {
      open,
      isMobile: computed(() => width.value < 1024),
      fetchButtonClass(btnItem) {
        // TODO:  This might need to be cleaned up or changed.
        //        A lot going on here.
        if (props.subNav) {
          if (btnItem.current) {
            return " ";
          } else {
            return "text-gray-400 hover:text-gray-900 hover:bg-gray-50  dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-gray-500 dark:text-gray-400";
          }
        } else {
          if (btnItem.current) {
            // TODO: Maybe add accent color underline to current element?
            return "text-gray-700 hover:bg-white border-b-2 border-gray-500 dark:border-white dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-white";
          } else {
            return "text-gray-400 hover:border-b-2 hover:border-primary dark-hover:border-white hover:text-gray-900 dark-hover:bg-primaryDark dark-hover:text-white";
          }
        }
      },
    };
  },
};
</script>
<style>
.profile-menu ul::-webkit-scrollbar-thumb {
    background: white !important;
}

</style>
