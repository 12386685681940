<template>
    <div class="flex flex-col items-center mb-2 text-sm w-full">
        <div class="flex items-start justify-center w-full " :class="isMobile?'':'my-4'">
            <slot></slot>
            <div class="w-full lg:grid-cols-3 grid-cols-2 gap-y-2">
                <div v-if="!hideDivInMobile" class="grid lg:grid-cols-3 md:grid-cols-2 invisible md:visible md:gap-x-9 gap-y-3 flex-grow items-center col-span-3">
                <p v-for="label, idx in labels" :key="idx"  class="col-span-1  text-sm font-medium text-gray-700 dark:text-white">
                    {{ label }}
                </p>
                </div>
                <div v-for="(item, idx) in items" :key="item.id"
                    class="col-span-3 mb-2 lg:grid-cols-3 grid-cols-2 items-center custom-input " >
                    <ContactInfoInput @addNewRow="$emit('addNewRow')" @delete="$emit('deleteRow', idx)"
                        @onInput="(...args) => onInput(...args, idx)" :item="item" :keys="keys" :type="type"
                        :isLast="item === items[items.length - 1]" :isAlone="(items.length === 1)" :placeholder="title"
                        :suggestions="['Home', 'Work']" class="col-span-3" />
                </div>
                <div class="items-start md:pt-2 pt-0">
                    <button type="button" class="grey-slate-text dark:text-white" @click="$emit('addNewRow')">+ Add new line</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import ContactInfoInput from "@/components/inputs/ContactInfoInput";
import useBreakpoints from "@/utils/reactive/useBreakpoints"

export default {
    name: "ContactInfoDynamicForm",
    components: { ContactInfoInput, },
    props: {
        items: {
            type: Array,
            required: true
        },
        keys: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        type: {
            type: Array,
            required: false,
            default: () => ["text","text"]
        },
          
        errors: Object,
        labels: Array
    },
    setup(props, { emit }) {
        const { width } = useBreakpoints()

        if (props.items.length === 0) emit('addNewRow')
        return {
            options: computed(() => ['Home', 'Work', 'Other']),
            onInput(value, key, idx) {
                emit('onInput', value, key, idx)
            },
            isMobile: computed(() => width.value < 1024),
            hideDivInMobile: computed(() => width.value < 767),
        }
    }
}
</script>
