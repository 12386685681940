<template>
  <div class=" mt-5  bg-white dark:bg-primaryDark  rounded-lg ">
    <div class="mb-4  border-gray-200 dark:border-gray-700">
      <ProfileNavbar class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6"
        @change="changeComponent" :navigation="navigation" :subNav="false" />

        <ProfileSliderMenu
    :editMode="editProfileState"
            class="lg:mt-0 dark:text-white col-start-0 relative col-span-10 md:col-start-0 md:col-span-12 lg:col-start-3 lg:col-span-7 items-center flex items-center lg:justify-between whitespace-pre"
            @change="changeComponent"
            :navigation="navigation"
             version="2"
      />
      <div class="col-start-1 col-span-9 md:col-start-3 md:col-span-7">
        <transition name="fade">
          <component :is="currentComponent" />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import localStorage from 'store2'
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import { ref } from "vue";
import General from '@/components/companies/viewCompany/Settings/General'
import Contact from '@/components/companies/viewCompany/Settings/Contact'
import Travel from '@/components/companies/viewCompany/Settings/Travel'
import Documentation from '@/components/companies/viewCompany/Settings/Documentation'
import Statics from '@/components/companies/viewCompany/Settings/Statics'
import registerEvents from '../../../utils/DisableSearchBoxInInputs'

import ProfileSliderMenu from "@/components/widgets/profileNavbar/ProfileSliderMenu";

const navigation = ref([
  {
    name: "General",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "General",
    current: true,
  },
  {
    name: "Contact",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "Contact",
    current: false,
  },
  // {
  //   name: "Travel",
  //   onClick: function () {
  //     onNavClick(this, navigation.value);
  //   },
  //   component: "Travel",
  //   current: false,
  // },
  // {
  //   name: "Documentation",
  //   onClick: function () {
  //     onNavClick(this, navigation.value);
  //   },
  //   component: "Documentation",
  //   current: true,
  // },
  // {
  //   name: "Statics",
  //   onClick: function () {
  //     onNavClick(this, navigation.value);
  //   },
  //   component: "Statics",
  //   current: false,
  // },
]);

function onNavClick(activeNode, navigation) {
  for (let i = 0; i < navigation.length; i++) {
    navigation[i].current = false;
  }
  activeNode.current = true;
  setTimeout(registerEvents, 500);
}

function changeCurrentByName(name) {
  for (let i = 0; i < navigation.value.length; i++) {
    let cmp = navigation.value[i];
    cmp.current = (cmp.component === name);
  }
}
export default {
  name: "CompanySettings",
  components: { ProfileNavbar, General, Contact, Travel, Documentation, Statics,ProfileSliderMenu },
  setup() {
    let savedTab = localStorage.get('companyProfessionalEditTab')
    console.log(savedTab)
    let currentComponent = ref("General");
    if (savedTab !== null) {
      currentComponent.value = savedTab
      changeCurrentByName(savedTab)
    }else{

      let component ="General";
      currentComponent.value=component;
      changeCurrentByName(component)
    }

    return {
      navigation,
      currentComponent,
      changeComponent(component) {
        localStorage.set("companyProfessionalEditTab", component)
        currentComponent.value = component;
      },
    }
  },
  beforeUnmount() {
    // Reset navbar indicator
    for (let i = 0; i < this.navigation.length; i++) {
      this.navigation[i].current = false;
    }
    this.navigation[0].current = true;
  },
}
</script>