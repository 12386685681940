<template>
  <ContentWrapper @onClick="editActive = true" :editMode="editMode" :showTitleIcon="false" title="General"
    titleIcon="UserIcon">
    <div v-if="general" class="about_info dark:text-white mt-5">
      <div class="flex items-center gap-2">
        <p class="strong py-1 text-sm dark:stroke w-5" v-if="general.type && general.type.length > 0">
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 fill-current text-gray-400 dark:text-white">
            <path
              d="M15.5835 16.3397H20.1668V14.5064C20.1668 12.9876 18.9356 11.7564 17.4168 11.7564C16.5409 11.7564 15.7605 12.1659 15.2569 12.804M15.5835 16.3397H6.41683M15.5835 16.3397V14.5064C15.5835 13.9048 15.4676 13.3303 15.2569 12.804M6.41683 16.3397H1.8335V14.5064C1.8335 12.9876 3.06471 11.7564 4.5835 11.7564C5.45947 11.7564 6.23978 12.1659 6.74339 12.804M6.41683 16.3397V14.5064C6.41683 13.9048 6.53272 13.3303 6.74339 12.804M6.74339 12.804C7.41921 11.1156 9.07041 9.92302 11.0002 9.92302C12.9299 9.92302 14.5811 11.1156 15.2569 12.804M13.7502 4.42302C13.7502 5.9418 12.5189 7.17302 11.0002 7.17302C9.48138 7.17302 8.25016 5.9418 8.25016 4.42302C8.25016 2.90424 9.48138 1.67302 11.0002 1.67302C12.5189 1.67302 13.7502 2.90424 13.7502 4.42302ZM19.2502 7.17302C19.2502 8.18554 18.4294 9.00635 17.4168 9.00635C16.4043 9.00635 15.5835 8.18554 15.5835 7.17302C15.5835 6.1605 16.4043 5.33969 17.4168 5.33969C18.4294 5.33969 19.2502 6.1605 19.2502 7.17302ZM6.41683 7.17302C6.41683 8.18554 5.59602 9.00635 4.5835 9.00635C3.57097 9.00635 2.75016 8.18554 2.75016 7.17302C2.75016 6.1605 3.57097 5.33969 4.5835 5.33969C5.59602 5.33969 6.41683 6.1605 6.41683 7.17302Z"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </p>
        <p class="strong py-1 text-sm" v-if="general.type && general.type.length > 0">
          {{ general.type }}
        </p>
      </div>

      <div class="flex gap-2 items-center mt-4 md:mt-4">
        <p class="strong py-1 text-sm  w-5" v-if="general.level && general.level.length > 0">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 fill-current text-gray-400 dark:text-white">
            <path
              d="M8.99984 3.50635V1.67301M8.99984 3.50635C7.98732 3.50635 7.1665 4.32716 7.1665 5.33968C7.1665 6.3522 7.98732 7.17301 8.99984 7.17301M8.99984 3.50635C10.0124 3.50635 10.8332 4.32716 10.8332 5.33968C10.8332 6.3522 10.0124 7.17301 8.99984 7.17301M3.49984 14.5063C4.51236 14.5063 5.33317 13.6855 5.33317 12.673C5.33317 11.6605 4.51236 10.8397 3.49984 10.8397M3.49984 14.5063C2.48732 14.5063 1.6665 13.6855 1.6665 12.673C1.6665 11.6605 2.48732 10.8397 3.49984 10.8397M3.49984 14.5063V16.3397M3.49984 10.8397V1.67301M8.99984 7.17301V16.3397M14.4998 14.5063C15.5124 14.5063 16.3332 13.6855 16.3332 12.673C16.3332 11.6605 15.5124 10.8397 14.4998 10.8397M14.4998 14.5063C13.4873 14.5063 12.6665 13.6855 12.6665 12.673C12.6665 11.6605 13.4873 10.8397 14.4998 10.8397M14.4998 14.5063V16.3397M14.4998 10.8397V1.67301"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </p>
        <p class="strong py-1 text-sm" v-if="general.level && general.level.length > 0">
          {{ general.level }}
        </p>
      </div>

      <div class="flex mt-4 items-center md:mt-6 gap-2 ">
        <p class="strong py-1 text-sm w-5" v-if="general.founded && general.founded.length > 0">
          <svg width="20"   height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 fill-current text-gray-400 dark:text-white">
            <path 
              d="M4.50016 12.7563C3.73933 12.7563 3.05183 13.068 2.55683 13.563C1.47516 14.6447 0.833496 19.173 0.833496 19.173C0.833496 19.173 5.36183 18.5314 6.4435 17.4497C6.8294 17.0658 7.09252 16.5759 7.19944 16.0421C7.30637 15.5084 7.25227 14.955 7.04403 14.452C6.83579 13.9491 6.48279 13.5194 6.02986 13.2175C5.57693 12.9156 5.04449 12.7551 4.50016 12.7563ZM5.151 16.1572C4.89433 16.4138 3.16183 16.8538 3.16183 16.8538C3.16183 16.8538 3.59266 15.1305 3.8585 14.8647C4.01433 14.6905 4.2435 14.5897 4.50016 14.5897C4.68165 14.5899 4.859 14.6439 5.00986 14.7448C5.16073 14.8457 5.27837 14.9889 5.34797 15.1565C5.41756 15.3241 5.43599 15.5086 5.40094 15.6867C5.36589 15.8647 5.27892 16.0285 5.151 16.1572ZM14.9685 11.5188C20.7985 5.68885 18.8552 1.15135 18.8552 1.15135C18.8552 1.15135 14.3177 -0.791984 8.48766 5.03802L6.20516 4.57968C5.90846 4.52271 5.60234 4.53885 5.31326 4.62669C5.02419 4.71453 4.76085 4.87143 4.546 5.08385L0.833496 8.80552L5.41683 10.7672L9.23933 14.5897L11.201 19.173L14.9135 15.4605C15.3443 15.0297 15.5368 14.4063 15.4177 13.8013L14.9685 11.5188ZM5.79266 8.93385L4.04183 8.18218L5.84766 6.37635L7.16766 6.64218C6.64516 7.40302 6.17766 8.20052 5.79266 8.93385ZM11.8243 15.9647L11.0727 14.2139C11.806 13.8288 12.6035 13.3613 13.3552 12.8389L13.621 14.1588L11.8243 15.9647ZM13.6668 10.2263C12.4568 11.4363 10.5685 12.4263 9.9635 12.7288L7.27766 10.043C7.571 9.44718 8.561 7.55885 9.78016 6.33968C14.0702 2.04968 17.3243 2.68218 17.3243 2.68218C17.3243 2.68218 17.9568 5.93635 13.6668 10.2263ZM12.7502 9.08968C13.7585 9.08968 14.5835 8.26468 14.5835 7.25635C14.5835 6.24802 13.7585 5.42302 12.7502 5.42302C11.7418 5.42302 10.9168 6.24802 10.9168 7.25635C10.9168 8.26468 11.7418 9.08968 12.7502 9.08968Z"
              fill="currentColor" />
          </svg>

        </p>
        <div class="flex text-sm" v-if="general.founded && general.founded.length > 0">
          {{ formatted }} {{ general.country }}
          <p v-if="general.country && general.country.length > 0">

          </p>
        </div>
      </div>


      <p class="strong py-1 text-sm" v-if="general.dissolved && general.dissolved.length > 0">

      </p>
      <p v-if="general.dissolved && general.dissolved.length > 0">

      </p>
    </div>
  </ContentWrapper>

  <CompanyGeneralSelect v-if="editActive" :general="general" :editActive="editActive" @onClose="editActive = false" />

</template>

<script>
import ContentWrapper from "../../../../widgets/ContentWrapper";
import CompanyGeneralSelect from './CompanyGeneralSelect.vue'
export default {
  name: "CompanyGeneral",
  components: {
    ContentWrapper,
    CompanyGeneralSelect
  },
  data() {
    return {
      editActive: false,
      date: this.general.dissolved,
    }

  },
  props: {
    general: {
      type: Object,
      required: true,
    },

    editMode: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    formatted() {

      let today = new Date(this.$props.general.founded);

      var options = { month: 'long', year: 'numeric', week: 'long', day: 'numeric' };
      let newDate = today.toLocaleDateString("en-US", options);
      return newDate;
    },

  },
};
</script>
