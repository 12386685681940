<template>
    <modal :modalActive="editActive" :duration="400" version="1" @close="$emit('onClose')">
        <div class="rounded-lg mb-9 p-4 bg-white">
            <div class="flex justify-between w-full">
                <div class="flex">
                    <p class="text-2xl text-left ml-2 font-medium mb-2">Edit General Info</p>
                    <div class="mt-2 ml-2">
                        <BaseToolTip text="Edit company Gerneral here"> </BaseToolTip>
                    </div>
                </div>
                <font-awesome-icon @click="localEdit = true" icon="fa-solid fa-pencil"
                    class="float-left self-center mr-2 text-gray-400" />
            </div>
            <p class="text-left ml-2 font-medium mb-4">Choose what to display</p>
            <div class="w-full grid grid-cols-2 gap-5 px-5 pt-5" v-if="!localEdit">
                <div class="flex justify-between">
                    <p class="pr-4 pb-2">Type</p>
                    <input name="won" type="checkbox" checked
                        className="mr-20 focus:ring-gray-500 mb-2 h-5 w-5 -mb-5 text-gray-600 border-gray-300 rounded" />
                </div>
                <div class="flex justify-between">
                    <p class="pr-4 pb-2">Founded Date</p>
                    <input name="won" type="checkbox" checked
                        className="mr-20 focus:ring-gray-500 mb-2 h-5 w-5 -mb-5 text-gray-600 border-gray-300 rounded" />
                </div>
                <div class="flex justify-between">
                    <p class="pr-4 pb-2">Number of Members</p>
                    <input name="won" type="checkbox"
                        className="mr-20 focus:ring-gray-500 mb-2 h-5 w-5 -mb-5 text-gray-600 border-gray-300 rounded" />
                </div>
                <div class="flex justify-between">
                    <p class="pr-4 pb-2">Level</p>
                    <input name="won" type="checkbox" checked
                        className="mr-20 focus:ring-gray-500 mb-2 h-5 w-5 -mb-5 text-gray-600 border-gray-300 rounded" />
                </div>
                <div class="flex justify-between">
                    <p class="pr-4 pb-2">Country</p>
                    <input name="won" type="checkbox" checked
                        className="mr-20 focus:ring-gray-500 mb-2 h-5 w-5 -mb-5 text-gray-600 border-gray-300 rounded" />
                </div>
                <!-- <button @click="saveCompanyBio">Test</button> -->
                <SaveCancelForm class="col-span-2" @onSave="saveCompanyGeneral" @onCancel="$emit('onClose')"
                    justifyOrientation="end" :display="true" :transparentButtons="true" />
            </div>
            <CompanyGeneralEdit v-else :general="general" :editActive="editActive" @onClose="localEdit = false" />

        </div>
    </modal>

</template>

<script>
import Modal from "../../../../widgets/Modal.vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import CompanyGeneralEdit from './CompanyGeneralEdit';
import BaseToolTip from "../../../../widgets/BaseToolTip.vue";
export default {
    name: "CompanyGeneralSelect",
    components: {
        Modal,
        SaveCancelForm,
        BaseToolTip,
        CompanyGeneralEdit
    },
    props: {
        general: {
            type: Object,
        },
        editActive: {
            type: Boolean,
        }
    },
    data() {
        return {
            updateGeneral: { ...this.general },
            localEdit: false
        };
    },
    methods: {
        async saveCompanyGeneral() {
            console.log("HANDLE SAVE COMPANY VISIBEL PREFERENCE")
            // await this.$store.dispatch("editCompany", { data: { ...this.updateGeneral } })
            // await this.$store.dispatch("fetchCompany", this.$route.params.id)
            this.$emit("onClose")
        }
    }
}
</script>