<template>
    <!-- profile -->
    <div class="p-4" id="profile" role="tabpanel" aria-labelledby="profile-tab"   >
        <h1 class="md:text-3xl text-2xl font-medium dark:text-white">
          Edit contact information
        </h1>
      </div>
        <div class="px-12">
          <p class="text-sm dark:text-white px-4 mt-2">
            Here you can fill in all your , such as your name,
            stage name, contact info etc. You can control which of these are
            displayed in the header of your profile, but they are also your
            contact information for contacts sheets etc. If allowed, it will be
            possible to download a .vcard file with contact information to
            insert into phone and/or computer contacts directory.
          </p>
          <form class="md:w-full lg:w-full" @submit.prevent="onSubmit">
            <h2 class="text-sm font-bold py-4 dark:text-white"> Contact information </h2>
            <div class="bg-white dark:bg-primaryDark grid-cols-x-contact">
            
              <div class="bg-white dark:bg-primaryDark grid-cols-x-contact">
              <div class="flex items-center mb-4 mr-12">
                
                <!-- <div class="h-px w-full bg-gray-400"></div> -->
              </div>
              <div class="flex gap-2 dark:text-white mb-2">
                <PhoneIcon class="w-6 transform text-gray-500 dark:text-white" />
                <p class="font-sm">Phone numbers</p>
                <BaseToolTip text="Add phone number"> </BaseToolTip>
              </div>
              <div class="pl-0 md:pl-8 dark:text-white">
                <ContactInfoDynamicForm @add-new-row="addNewPhoneRow" @delete-row="onPhoneDelete"
                  @onInput="onPhoneChange" :items="state.phoneNumbers" :keys="phoneKeys"
                  :errors="v$.phoneNumbers.$errors" :type="['number','text']" textFormate="capitalize" :labels="['Phone number', 'Phone label']"
                  title="Phone Number">
                </ContactInfoDynamicForm>
              </div>
              <div class="flex mt-8 gap-2 dark:text-white  mb-2">
                <MailIcon class="w-6 transform text-gray-500 dark:text-white" />
                <p class="font-sm">Email addresses</p>
                <BaseToolTip text="Add email here"> </BaseToolTip>
              </div>
              <div class="pl-0 md:pl-8 dark:text-white">
                <ContactInfoDynamicForm @add-new-row="addNewEmailRow" @delete-row="onEmailDelete"
                  @onInput="onEmailChange" :items="state.emails" :keys="emailKeys" :errors="v$.emails.$errors"
                  :labels="['Email address', 'Email label']" :type="['email','text']" textFormate="capitalize" title="Email">
                </ContactInfoDynamicForm>
              </div>
              <div class="flex mt-10 gap-2 dark:text-white  mb-2">
                <GlobeAltIcon class="w-6  transform text-gray-500 dark:text-white" />
                <p class="font-sm ">Web addresses</p>
                <BaseToolTip text="Add web addresses"> </BaseToolTip>

              </div>
              <div class="pl-0 md:pl-8 dark:text-white">
                <ContactInfoDynamicForm @add-new-row="addNewWebsiteRow" @delete-row="onWebsiteDelete"
                  @onInput="onWebsiteChange" :items="state.websites" textFormate="capitalize" :keys="websiteKeys"
                  :errors="v$.websites.$errors" :type="['text','text']" :labels="['Website address', 'Website label']" title="Website">

                </ContactInfoDynamicForm>
              </div>
            </div>
              
              <div class="flex mt-8 gap-2 items-center dark:text-white" ref="top">
              <LocationMarkerIcon class="w-6 transform text-gray-500 dark:text-white" />
              <p class="font-sm">Addresses</p>
              <BaseToolTip text="Add email here"> </BaseToolTip>
            </div>

            <div class="pl-0 md:pl-8">
              <CompanyAddressInfoForm :hideButton="false" textFormate="capitalize"
                class="mt-2 bg-white dark:bg-primaryDark" />
            </div>
            <div class="flex items-center mb-4">
              <p class=" whitespace-nowrap pl-10 pr-2 py-2 font-semibold"></p>
              <!-- <div id="line" class="h-px w-full mr-12 bg-gray-400 "></div> -->
            </div>
            <div class="flex items-center gap-2 dark:text-white">
              <StatusOnlineIcon class="w-6 transform text-gray-500 dark:text-white" />
              <p class="font-sm ">Social Media Handles</p>
              <BaseToolTip text="Add social media"> </BaseToolTip>
            </div>
            <div class="pl-0 md:pl-8 dark:text-white">
              <CompanySocialForm class="mt-2 bg-white dark:bg-primaryDark" />
            </div>
            </div>
            
            <SaveCancelForm class="col-span-2 col-start-3 pb-5" :display="true" justifyOrientation="end"
                    @onSave="onSubmit" @onCancel="$emit('onClose')"  />

          
            </form> 

      </div>
</template>
<script>
import { useStore, mapGetters } from "vuex";
import { computed, reactive} from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
// import { notify } from "@kyvg/vue3-notification";
import { websiteValidator, emailValidator } from '@/utils/VuelidateValidators';
import ContactInfoDynamicForm from "@/components/forms/ContactInfoDynamicForm";
import { GlobeAltIcon, MailIcon, PhoneIcon } from "@heroicons/vue/outline/esm";
import { LocationMarkerIcon, StatusOnlineIcon } from "@heroicons/vue/outline/esm";
import CompanyAddressInfoForm from "@/components/forms/CompanyAddressInfoForm";
import CompanySocialForm from "@/components/forms/CompanySocialForm";
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
import { createToaster } from "@meforma/vue-toaster";
import SaveCancelForm from "@/components/forms/SaveCancelForm"

    export default {
    name: "Contact",
    components: {
    ContactInfoDynamicForm,
    GlobeAltIcon, MailIcon, PhoneIcon,
    CompanyAddressInfoForm, CompanySocialForm,
    LocationMarkerIcon, StatusOnlineIcon,BaseToolTip,SaveCancelForm
  },
  computed: {
    ...mapGetters(["getCompanyPhoneNumbers"])

  },
  setup() {
    const store = useStore();
    const state = reactive({
      phoneNumbers: computed(() => store.getters.getCompanyPhoneNumbers),
      emails: computed(() => store.getters.getCompanyEmails),
      websites: computed(() => store.getters.getCompanyWebsites),
      addresses: computed(() => store.getters.getCompanyAddresses),
      socials: computed(() => store.getters.getCompanySocials),

    });

    
    const rules = {
      name: { required },
      type: { required },
      level: { required },
      founded: { required },
      dissolved: { required },
      emails: {
        hasMail: emailValidator({
          email: { email },
        })
      },
      websites: {
        hasUrl: websiteValidator({
          url: { required }
        })
      },
      phoneNumbers: {}

    };

  
    const v$ = useVuelidate(rules, state, { $lazy: true });

    return {
      v$,
      state,
      store,
      companyTypes: computed(() => store.getters.getCompanyTypes),
      companyLevels: computed(() => store.getters.getCompanyLevels),
      countries: computed(() => store.getters.getCountries),
      addresses: computed(() => store.getters.getCompanyAddresses),


      // ==========PHONE==========
      addNewPhoneRow: () => store.commit('addNewCompanyPhoneNumber'),
      async onPhoneChange(value, key, idx) {
        store.commit('editCompanyPhoneByIdx', { value, key, idx })
      },
      async onPhoneDelete(idx) {
        const result = confirm("Are you sure you want to delete?")
        try {
          const phone = store.getters.getCompanyPhoneByIdx(idx)
          if (phone.id && result) {
            await store.dispatch('deleteCompanyPhoneNumber', phone.id)
          }
          store.commit('removeCompanyPhoneByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },
      phoneKeys: computed(() => ['phoneNumber', 'label']),
      // ==========WEBSITE==========
      addNewWebsiteRow: () => store.commit('addNewCompanyWebsite'),
      async onWebsiteChange(value, key, idx) {
        store.commit('editCompanyWebsiteByIdx', { value, key, idx })
      },
      async onWebsiteDelete(idx) {
        const result = confirm("Are you sure you want to delete?")
        try {
          const website = store.getters.getCompanyWebsiteByIdx(idx)
          if (website.id && result) {
            await store.dispatch('deleteCompanyWebsite', website.id)
          }
          store.commit('removeCompanyWebsiteByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },
      websiteKeys: computed(() => ['url', 'title']),
      // ==========EMAIL==========
      addNewEmailRow: () => store.commit('addNewCompanyEmail'),
      async onEmailChange(value, key, idx) {
        store.commit('editCompanyEmailByIdx', { value, key, idx })
      },
      async onEmailDelete(idx) {
        const result = confirm("Are you sure you want to delete?")
        try {
          const email = store.getters.getCompanyEmailByIdx(idx)
          if (email.id && result) {
            await store.dispatch('deleteCompanyEmail', email.id)
          }
          store.commit('removeCompanyEmailByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },
      emailKeys: computed(() => ['email', 'label']),
      // ==========ADDRESS==========
      addNewAddressRow: () => store.commit('addNewAddress'),
      async onAddressChange(value, key, idx) {
        console.log('TO BE IMPLEMENTED', value, key, idx)
      },
      async onAddressDelete(idx) {
        console.log('TO BE IMPLEMENTED', idx)
      },
      addressItems: computed(() => store.getters.addresses),
      addressKeys: computed(() =>
        store.getters.addresses.length > 0
          ? Object.keys(store.getters.addresses[0]).filter(key => key !== 'id')
          : []
      ),
     
    };
  },
  methods: {
  async onSubmit() {
    const toast = createToaster({position:"top-right"});
           let cityError= this.state.addresses;
        
        this.v$.$touch();
        if(!cityError.[0].city || !cityError.[0].country) {
          window.scrollTo(0, this.$refs.top.offsetTop);
          toast.error(`Some required fields were not provided`);
          return false
        }


      this.store.commit('removeCompanyEmailErrorProperty')
      this.store.commit('removeCompanyAddressErrorProperty')
      this.store.dispatch('saveCompanyPhoneNumbers')
      this.store.dispatch('saveCompanyWebsites')
      this.store.dispatch('saveCompanyEmails')
      this.store.dispatch('saveCompanyAddresses')
      this.store.dispatch('saveCompanySocials')
      this.store.dispatch("fetchCompany", this.store.getters.getCompanyId)
      // notify({
      //   text: "Contact info has been updated successfully 🎉",
      //   type: "success",
      //   duration: 2000
      // })
      toast.success(`Contact info has been updated successfully`);
  }
}
    }
</script>