<template>
  <div class="mt-5">
    <div class="flex justify-between align-middle bg-white rounded-t px-8 pt-5 dark:bg-primaryDark">
      <div class="flex flex-row dark:text-white">
        <!-- <div class="flex items-center justify-center">
          <font-awesome-icon icon="fa-solid fa-users" size="lg" />
        </div> -->
        <h2 class="text-xl">People</h2>
      </div>
    </div>
    <DynamicTable :filters="peopleRangeFilters" :rangeFilters="peopleRangeFilters" :link="ProfileViewLink"
      @sort="onSortParamEntry" @next="onNextPage" @prev="onPreviousPage" :searchFunc="onSearchParamEntry"
      :reset="resetFilters" :update="update" :editDeleteMode="true"
      class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6" />
  </div>
</template>
<style>
.grid-image-width{
    width: 80px;
    padding: 7px;
}

</style>
<script>
import { useStore } from 'vuex'
import { computed } from "vue";
import { useRouter } from "vue-router";
import DynamicTable from "@/components/widgets/dynamicTable/DynamicTable";

export default {
  name: "CompanyMembers",
  components: { DynamicTable },
  props: {
    editDeleteMode: {
      type: Boolean,
      default: false
     }
    },
    async beforeMount(){
     await this.$store.dispatch('dynamicTableLoadCompanyMembers')
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    function onTrashed(id) {
      var newArr = {
        "id": parseInt(id),
        "companyId":parseInt(store.getters.getCompanyId),
        "role": "TestROle",
        "department":"",
        "started":"",
        "left":"",
        "delete": true,
      }
      store.dispatch('deleteCompanyMember', newArr)
      store.dispatch('dynamicTableLoadCompanyMembers')

    }
    function onProfileView(id){
      router.push({ name: 'Profile', params: { id: id }})
    }

    return {
       router,
      store,
      onProfileView,
      ProfileViewLink: computed(() => {
        return {func: onProfileView, fieldName: "Name"}
      }),
      onTrashedLink: computed(() => {
          return { func: onTrashed, fieldName: "Name" }
      }),
      peopleFilters: computed(() => store.getters.getPeopleFilters),
      peopleRangeFilters: computed(() => store.getters.getPeopleRangeFilters),
      async onSearchParamEntry(searchString) {
        store.commit('resetDynamicTableSearchParams')
        store.commit('setDynamicTableSearchParam', searchString)
        await store.dispatch('dynamicTableLoadCompanyMembers')
      },
      async update() {
        await store.dispatch('dynamicTableLoadCompanyMembers')
      },
      async resetFilters() {
        store.commit('resetPeopleFilters')
        await store.dispatch('dynamicTableLoadCompanyMembers')

      },
      async onSortParamEntry(key) {
        if (key === 'Name') {
          store.commit('setPeopleSortBy', 'name')
        } else if (key === 'Role') {
          store.commit('setPeopleSortBy', 'role')
        } else if (key === 'Department') {
          store.commit('setPeopleSortBy', 'department')
        }
        await store.dispatch('dynamicTableLoadCompanyMembers')
      },
      onNextPage: () => store.dispatch('companyMembersPageNext'),
      onPreviousPage: () => store.dispatch('companyMembersPagePrevious')
    }
  }
}
</script>
