<template>
  <div ref="photoPicker" class="flex flex-col p-8
    bg-white shadow-lg rounded-xl dark:bg-primaryDark dark:text-white ">
    <div class="flex">
      <div>
        <p class="text-sm font-semibold mb-4">{{ header }}</p>
      </div>
    </div>
    <div class="flex h-full flex-col justify-between">
      <div class="grid md:grid-cols-5 sm:grid-cols-3 gap-4 pt-7  pb-7 bg-white mb-3
          dark:bg-primaryDark dark:text-white image_pop no-scroll-bar pr-1 overflow-y-scroll overflow-hidden h-auto max-h-80 ">
        <PhotoUnit v-for="(img, idx) in images" :showRandom="showRandom" :key="idx" @select="select($event, img)" :image="img"
          class="sm:col-span-1 " :class="['order-' + img.id]" />
      </div>
      <div class="lg:flex items-center">
        <slot></slot>
         <SaveCancelForm @onCancel="$emit('close')" @onSave="submit"  :display="true"
          justifyOrientation="end" class="" />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from 'vuex'
import PhotoUnit from "@/components/widgets/photoPicker/PhotoUnit";
import SaveCancelForm from '@/components/forms/SaveCancelForm';
export default {
  name: "PhotoPicker",
  components: { PhotoUnit, SaveCancelForm },
  props: {
    images: Array,
    max: Number,
    header: String,
    showRandom:{
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const selectedPictures = ref([])
    const store = useStore()
    for (let i = 0; i < props.images.length; i++) {
      if (props.images[i].primary) selectedPictures.value.push(props.images[i])
    }
    return {
      store,
      selectedPictures,
      select(selected, pic) {
        if (!selected) {
          if (selectedPictures.value.length < props.max) {
            selectedPictures.value.push(pic)
            pic.primary = true
          }
        } else {
          const idx = selectedPictures.value.indexOf(pic)
          selectedPictures.value.splice(idx, 1)
          pic.primary = false
        }
      },
      async submit() {
        //await this.store.dispatch('fetchBasicProfile')
        emit('submit', selectedPictures.value)
        emit('close')
      }
    }
  },
  mounted() {
    const top = this.$refs.photoPicker.offsetTop
    window.scroll(0, top)
  }
}
</script>