<template>
  <div class="w-full p-4 md:p-0 md:grid grid-cols-6 gap-4">
    <div id="main-content" class="col-span-4">
      <CompanyAbout :bio="(company.bio)?company.bio:''" :userIsAdmin="isCreator" :editMode="isCreator"  :short="short"
        :shortBio="(company.bio)?company.bio.substring(0, 500):null"></CompanyAbout>
        <CompanyPeople :previewItems="people" :roundedImages="true" :userIsAdmin="isCreator" :changeComponent="changeComponent" :navigation="navigation"></CompanyPeople>  
      <CompanyFeaturedPhotos :images="Images" :editMode="isCreator" :userIsAdmin="isCreator"></CompanyFeaturedPhotos>
      <CompanyProductions :editMode="isCreator" :userIsAdmin="isCreator" :changeComponent="changeComponent" :navigation="navigation"  ></CompanyProductions>
     
    </div>
    <div id="side-content" class="col-span-2 flex flex-col gap-4">
      <CompanyGeneral :general="companyGeneralInfo" :editMode="isCreator"></CompanyGeneral>
      <!-- <CompanyAffiliation :general="companyGeneralInfo" :editMode="isCreator"></CompanyAffiliation> -->
      <!-- <CompanyAwards :awards="awards" :editMode="editMode"></CompanyAwards> -->
    </div>
  </div>
  <!-- {{ companyMembers }} -->
</template>

<script>
import { computed,ref } from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import CompanyAbout from "../components/companyOverview/about/CompanyAbout.vue";
// import CompanyAwards from "../components/companyOverview/awards/CompanyAwards";
import CompanyFeaturedPhotos from "../components/companyOverview/featuredPhotos/CompanyFeaturedPhotos";
import CompanyGeneral from "../components/companyOverview/general/CompanyGeneral";
import CompanyPeople from "../components/companyOverview/people/CompanyPeople";
import CompanyProductions from "../components/companyOverview/production/CompanyProductions";
// import CompanyAffiliation from "../components/companyOverview/Affiliations/CompanyAffiliation";
// import PreviewRow from'@/components/widgets/previewRow/PreviewRow.vue';
export default {
  name: "CompanyOverview",
  components: {
    CompanyAbout,
    CompanyGeneral,
    CompanyFeaturedPhotos,
    // CompanyAwards,
    CompanyProductions,
    CompanyPeople,
    // CompanyAffiliation
  },
  
  props: {
    company: {
      type: Object,
    },
   changeComponent:Function,
    navigation:{type:Array},
    editMode: {
      type: Boolean,
      required: true,
    },
  },
   
  async beforeMount() {
    await this.$store.dispatch("fetchCompany", this.$route.params.id);
    await this.$store.dispatch("fetchCompanyProductionSettings");
    await this.$store.dispatch('fetchProductions', { first: 5, after: "", before: "", searchFilter: "", sortBy: "" })
    
  },
  
   setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute()
    const short = ref(true);
   
    store.dispatch('fetchCompanyMembers',{ first:4, after: "", before: "", searchFilter: "", sortBy: "", companyId:route.params.id})
    const people = computed(() => {
      return store.getters.getTableFormattedPeople.map(item => {
        const go = () => router.push({ name: 'Profile', params: { id: item.id } })
        return { title: item.name, subtitle: item.job, image: item.image, go }
      })
    })
 
    return {
      people,
      short,
      companyGeneralInfo: computed(() => store.getters.getCompanyGeneral),
      awards: computed(() => store.getters.getCompanyAwards),
      companyMembers: computed(() => store.getters.getTableFormattedCompanyMembers),
      productions: computed(() => store.getters.getProductions),
      Images: computed(() => store.getters.getCompnayImages),
      onClick(id) {
        router.push({ name: "Profile", params: { id } });
      },
      isCreator: computed(
        () => store.getters.getId === store.getters.getCompanyCreatedBy
      ), 
    };
  }
};
</script>
