<template>
  <div class="w-full flex items-start ">
    <div class="w-full flex flex-col ">
        <div class="w-full pb-4 mt-4" v-for="(address, idx) in state.addresses" :key="idx">
          <div class="block md:flex items-center" :ref="'addrForm-'+idx">
            <SmallTextInput class="w-12/12 md:w-4/6"  heading="Street address" :value="address.address" @update:value="address.address=$event" />
          </div>
          <div class="block md:flex items-center">
            <SmallTextInput class="w-12/12 md:w-4/6 my-1"  heading="Street address line 2"  :value="address.address2" @update:value="address.address2=$event" />
          </div>
          <div class="block md:flex items-center">
          </div>
          <div class="block md:flex items-center">
            <SmallTextInput class="w-12/12 md:w-4/12 my-1 md:pr-5"  :requiredIndicator="true" v-on:keyup="city(address.city)" heading="City" :value="address.city" @update:value="address.city=$event" />
            <SmallTextInput class="w-12/12 md:w-4/12 my-1"  heading="Postal code" :value="address.postcode" @update:value="address.postcode=$event" />
      
          </div>
          <p class="text-red-600 text-sm" v-if="address.errors && address.errors.city.hasError">{{address.errors.city.message}}</p>
          <div class="block md:flex items-center">
            <SmallTextInput class="w-12/12 md:w-4/6 my-1"  heading="State" :value="address.state" @update:value="address.state=$event" />
          </div>
          <div class="block items-end ">
             <SelectInput class="w-12/12 md:w-4/6 my-1 gap-1"  v-on:change="country(address.country)" heading="Country" :requiredIndicator="true" textFormate="capitalize" :options="countries" :value="address.country" @update:value="address.country=$event"></SelectInput>
           <div v-if="!hideButton" class="w-2/12 flex h-12 order-last" v-show="state.addresses.length > 1">
              <TrashIcon v-if="state.addresses.length > 1" @click="deleteAddress(idx)" class="w-6 ml-2 text-gray-500 cursor-pointer"/>
            </div>
            <p class="text-red-600 text-sm" v-if="address.errors && address.errors.country.hasError"> {{address.errors.country.message}}</p>
          </div>
       
        </div>
        <p @click="addAddress" v-if="!hideButton" class="cursor-pointer grey-slate-text">+ Add new line</p>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, reactive } from "vue";
import {  TrashIcon} from "@heroicons/vue/outline/esm";
import { required } from '@vuelidate/validators'
import { addressValidator } from '@/utils/VuelidateValidators'
import SmallTextInput from "@/components/inputs/SmallTextInput";
import useVuelidate from "@vuelidate/core";
import SelectInput from "@/components/inputs/SelectInput";
export default {
  name: "CompanyAddressInfoForm",
  components: {SelectInput, SmallTextInput, TrashIcon},
  props: {
    hideButton: {
            type: Boolean,
            default: false
        }
    },
  setup() {
    const store = useStore()
    const state = reactive({
      addresses: computed(() => store.getters.getCompanyAddresses)
    })
    if (state.addresses.length < 1) store.commit('addNewCompanyAddress')
    const rules = {
      addresses: {
        validAddress: addressValidator({
          country: { required },
          city: { required },
          address:{required}
        
        })
      }
    }
    const v$ = useVuelidate(rules, state, {$lazy: true})
    return {
      state,
      v$,
      countries: computed(() => store.getters.getCountries),
      addAddress() { store.commit('addNewCompanyAddress')},
      async deleteAddress(idx){
        try{
          const address = store.getters.getCompanyAddressByIdx(idx)
          if(address.id) {
            await store.dispatch('deleteCompanyAddress', address.id)
          }
          store.commit('removeCompanyAddressByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      }
    }
  },
  methods: {
    country(validation) {
      console.log(this.state.addresses[0])
      if(validation.length > 0){
        this.state.addresses[0].errors.country.hasError=false
      
      }
      return true;
    },
    city(validation) {
      console.log(this.state.addresses[0])
      if(validation.length > 0){
        this.state.addresses[0].errors.city.hasError=false
        this.v$.$error=false
      }
      return true;
    },
  },
}
</script>