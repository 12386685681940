<template>
    <ContentWrapper :editMode="editMode" @onClick="photoPickerOn = true" :content="userIsAdmin" :showTitleIcon="true"
        title="Featured Photos" titleIcon="CameraIcon">
        <div class="mt-2 ">
            <div class="grid grid-cols-4 gap-4 h-2/3 justify-evenly filter grayscale">
                <img v-for="img in firstFourPictures" :key="img.id"  :src="img.image"
                    class="object-cover aspect-square h-full w-full relative" />
            </div>
            <SlideUpDown v-model="notFirstFour">
                <div class="grid grid-cols-4 gap-4 h-2/3 justify-evenly mt-3  filter grayscale  ">
                    <img v-for="img in notFirstFourPictures" :key="img.id" 
                        class="object-cover aspect-square h-full w-full relative" :src="img.image" />
                </div>
            </SlideUpDown>
            <div class="w-full ">
                <button @click="firstFour = !firstFour" type="button"
                    class="w-full text-right items-center mt-2.5 text-sm font-medium rounded underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white grey-slate text-sm">
                    {{ seeMore }}
                </button>
            </div>
        </div>

        <!-- <div class="flex justify-center items-center w-full"  disabled>

          <label for="text"
                class="flex flex-col border-grey justify-center items-center w-full h-28 bg-gray-100 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700  dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div class="flex flex-col justify-center items-center pt-5 pb-6">
                    <p class="text-black text-xl dark:text-gray-400"><span class="">Click to add photos</span></p>
                </div>
             </label>
        </div> -->

    </ContentWrapper>

    <!-- <Modal :modalActive="photoPickerOn" @close="photoPickerOn = false">
        <PhotoPicker @submit="submitMainImages" @close="photoPickerOn = false" v-if="photoPickerOn"
            :showRandom="showRandom" :images="localImages" :max="4" :header="photoModalHeading">
            <div class="flex items-center w-full">
                <Switch v-model="showRandom"
                    :class="[showRandom ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                    <span aria-hidden="true"
                        :class="[showRandom ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <span class="pl-3">Show Random</span>
            </div>
        </PhotoPicker>
    </Modal> -->

    <Modal :modalActive="photoPickerOn" @close="photoPickerOn = false">
        <PhotoPicker @submit="submitMainImages" @close="photoPickerOn = false" v-if="photoPickerOn"
            :showRandom="showRandom" :images="localImages" :max="4" :header="photoModalHeading">
            <div class="flex items-center w-full">
                <Switch v-model="showRandom"
                    :class="[showRandom ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                    <span aria-hidden="true"
                        :class="[showRandom ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                </Switch>
                <span class="pl-2 font-sm">Show Random</span>
            </div>
        </PhotoPicker>
    </Modal>
    <MediaImageModalWindow :modalActive="modalActive" :curIdx="idx" :images="images" @close="modalActive = false" />
</template>

<script>
import { Switch } from '@headlessui/vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import SlideUpDown from 'vue3-slide-up-down'
import PhotoPicker from '@/components/widgets/photoPicker/PhotoPicker'
import MediaImageModalWindow from '@/components/widgets/mediaImageWidget/MediaImageModalWindow'
import Modal from '@/components/widgets/Modal'
import ContentWrapper from '../../../../widgets/ContentWrapper'
export default {
    name: "CompanyFeaturedPhotos",
    components: { ContentWrapper, SlideUpDown, Modal, PhotoPicker, MediaImageModalWindow, Switch },
    data() {
        return {
            localImages: JSON.parse(JSON.stringify(this.images)) // deep copy of image prop
        }
    },
    props: {
        images: {
            type: Array,
            required: true
        },
        editMode: {
            type: Boolean,
            required: true
        }, userIsAdmin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        photoModalHeading() {
            return "Select upto 4 images to display";
        },
        seeMore() {
            if (this.firstFour) return "See more"
            return "See less"
        },
        firstFourPictures() {
            return this.images.filter(i => i.primary)
        },
        notFirstFourPictures() {
            return this.images.filter(i => !i.primary)
        }
    },
    watch: {
        showRandom(newVal) {
            console.log("changed showRandom")
            if (newVal) {
                this.localImages.map((img) => {
                    img.primary = false
                })
                let nums = []
                for (let i = 0; i < 4; i++) {
                    let n = this.generateRandom(nums)
                    this.localImages[n].primary = true
                    nums.push(n);
                }
            } else {
                this.localImages = JSON.parse(JSON.stringify(this.images)) // deep copy of image prop
            }
        },
        images() {
            this.localImages = JSON.parse(JSON.stringify(this.images))
        }
    },
    methods: {
        generateRandom(nums) {
            const n = Math.floor((Math.random() * this.images.length));
            if (nums.includes(n)) {
                return this.generateRandom(nums)
            }
            return n;
        },
        async submitMainImages(img) {
            console.log("SAVE COMPANY'S PRIMARY IMAGES TO API", img)
            this.$store.dispatch("updateCompnayImages", { images: this.localImages, showRandom: this.showRandom })
        },
    },
    setup() {
        const store = useStore()
        const firstFour = ref(true)
        const photoPickerOn = ref(false)
        const modalActive = ref(false)
        const idx = ref(0)
        const showRandom = ref(false)
        // const showRandom = computed(() =>  store.getters.getCompanyImagesMode)

        return {
            firstFour,
            modalActive,
            idx,
            showRandom,
            notFirstFour: computed(() => !firstFour.value),
            photoPickerOn,
            userPhotos: store.getters.getProfileImages,
            activateModal(i) {
                idx.value = i
                modalActive.value = true
            }
        }
    }
}
</script>