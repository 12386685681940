<template>
  <label>
    <div class="flex gap-2 mb-1" :class="labelClass()">
      {{ heading }}
      <BaseToolTip v-if="tooltip" :text=toolText> </BaseToolTip>
    </div>
  </label>
  <div class="border-2 mx-0 border-gray py-4 flex flex-wrap rounded-lg" 
    @click="$refs.inputSearch.focus()"  @blur="handleItemClickOutSide()" >

    <div v-for="el, idx in elements" :key="idx"
      class="bg-gray-100 dark:bg-gray-600 mx-3 my-2 shadow px-4 py-2 rounded-md flex items-center ">
      {{ el.name }}
      <XCircleIcon class="w-5 h-5 ml-2 cursor-pointer" @click="deleteEntry(el.id)" />
    </div>
    <div class="">
    <div class="flex flex-col items-center "   >
      <input ref="inputSearch"  type="text" v-model="currentValue"  @mouseup="handleItemClick" @keyup="handleItemClick"  @focus="inputFocused = true"
        class="border-transparent mx-3 my-2 rounded dark:bg-primaryDark" placeholder="Search people..." :class="textFormate" />
      <!-- <p v-if="inputFocused" class="text-gray-500">enter to create</p> -->
    </div>
    
    <div v-if="filteredList && toggled !='' || filteredList && currentValue >0"
      class="w-full  max-h-46 scroll_items overflow-y-scroll flex h-auto flex-col items-center " >
      <!-- <h2 class="text-gray-500 w-full pt-2 pb-2 bg-gray-100 dark:bg-gray-900">Suggestions:</h2> -->
      <div class="w-full  py-2 flex flex-wrap cursor-pointer  ">
        <transition-group name="list-complete ">
          <div class="flex w-full items-center hover:bg-gray-300 child"
            @mousedown="submitNewEntry(suggestion.id, suggestion.fullName);" v-for="suggestion, idx in filteredList"
            :key="idx">
            <img class="empImage p-2 people-width rounded-full" :src="suggestion.profilePhoto" alt="employee" />
            <div class="w-full text-left"> {{ suggestion.fullName }} </div>

          </div>

        </transition-group>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { XCircleIcon } from '@heroicons/vue/solid/esm'
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
import { ref } from 'vue'

export default {
  name: "MultiFieldAddPeopleInput",
  components: { XCircleIcon, BaseToolTip },
  data() {
    return {
      currentValue: '',
      suggestionClass:'',
      toggled:'',
      postList: this.$store.getters.getSearchPeople,
    };
  },
  props: {
    removeElement: Function,
    searchElement: Function,
    addElement: Function,
    elements: Array,
    suggestions: Array,
    textFormate: String,
    heading: String,
    tooltip: {
      type: Boolean,
      default: false
    },
    toolText: String,
  },
  computed: {
    filteredList() {
      return this.postList.filter(post => {
        return post.fullName.toLowerCase().includes(this.currentValue.toLowerCase())
      })
    }
  },
  setup(props) {
    // let currentValue = ref("") 
    const inputFocused = ref(false)
    const store = useStore();
    return {
      store,
      inputFocused,
      submitNewEntry(id, name) {
          props.addElement(id, name)
       },
      deleteEntry(title) {
        props.removeElement(title)
      },
      changeTitle(val) {
        this.currentSkill = val;


      } 
    }
  }, methods: {
    labelClass() {
      return "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-white"

    },
    handleItemClick() {
      setTimeout(() => {
        this.toggled = 1; 
      }, 500)
   
    },handleItemClickOutSide(){
      console.log('LostFocus');
      this.toggled = ''; 
    }
  
  }
}

</script>
<style scoped>
.scroll_items {
    max-height: 200px;
    position: absolute;
    z-index: 99999999;
    background: #ffffff;
    width: 80%;
    box-shadow: 0px 0px 10px #a79c9c;
}
.list-complete-item {
  transition: all 0.8s ease;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}

/* This removes all focus effects */
input:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}
</style>>
