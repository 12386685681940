<template>
  <div @click="select" class="relative" :class="showRandom ? 'showRandom' :  '' ">
    <div class="w-full h-full " :class="[ image.primary ? 'border-4 dark:border-white border-gray-500' : '']">
      <CheckIcon v-if="image.primary" class="w-1/12 lg:w-2/12 absolute right-0 top-0 dark:bg-white dark:text-gray-500 bg-gray-500 text-white" />
      <img :src="image.image" class="object-cover aspect-square w-full h-full" />
    </div>
  </div>
</template>
<script>
import { CheckIcon } from '@heroicons/vue/solid'
export default {
  name: "PhotoUnit",
  components: { CheckIcon },
  props: {
    image: Object,
    showRandom:{
      required: false,
      default: false
    }
  },
  setup(props, {emit}) {
    return {
      select() {
        emit('select', props.image.primary)
      }
    }
  }
}
</script>
<style>
.showRandom::after{
   content: "";
    width: 100%;
    height: 100%;
    background-color: #c4c4c47a;
    z-index: 999999;
    position: ABSOLUTE;
    top: 0;
    left: 0;
}
</style>