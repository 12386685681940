<template>
  <div class="">
    <main class="grid grid-cols-10">
      <div class=" grid col-span-10 bg-white pt-8 dark:bg-primaryDark lg:pb-0">
        <CompanyHeader :headerInfo="headerInfo" :editMode="isCreator">
          <CompanyNavbar
            class="dark:text-white col-start-0 relative col-span-10 md:col-start-2 md:col-span-7 lg:col-start-3 lg:col-span-7 flex items-center lg:justify-between"
            @change="changeComponent" :navigation="(isCreator == true) ? navigation : getNavBarMenu()" version="2">
            <div v-click-outside="hideToggle"
              v-if="(currentComponent == 'CompanyProductions' && isCreator == true ) ? setAddNewButtonForCreators(currentComponent) : false"
              class="lg:text-right top-0 absolute lg:relative sm:inherit right-0 float-right mt-1 lg:mt-0 w-full lg:w-auto text-center">
             
               <!-- <div class="tooltip relative inline-block ">
                <button @click="toggle"  id="dropdownDividerButton"
                  data-dropdown-toggle="dropdownDivider"
                  class="cursor-pointer px-5 py-1 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark"
                  type="button"> Add <svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                  </svg></button>  -->
                <!-- Dropdown menu -->
                <!-- <div id="dropdownDivider" :class="showCreditCard"
                  class="z-10 w-32 shadow-basic-sm bg-white absolute text-left rounded divide-y divide-gray-100 dark:text-white dark:bg-primaryDark">
                  <ul class=" text-sm text-black dark:text-white py-3 pr-3" aria-labelledby="dropdownDividerButton">
                    <li class="no-scroll-bar overflow-hidden overflow-y-scroll h-auto max-h-24">
                      <a @click="changeButtonState(option)"
                        class="block hover:bg-gray-100 p-2.5  dark-hover:bg-backgroundDark cursor-pointer "
                        v-for="(option, index) in addBtnOptions" :key="index">{{ option }}
                      </a>
                    </li>
                  </ul>
                </div>
               </div> -->
                  </div>
            <div 
            v-if="(currentComponent == 'CompanyProductionss' && isCreator == true) "
              class=" pt-2 lg:pt-0 text-center lg:w-auto lg:text-right top-0 lg:relative sm:inherit right-0 lg:float-right"
            >
              <!-- class= mb-2" -->
              <button @click="imagePoiSelectorOn = true"
                class="mr-2 relative inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 shadow-sm 0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
               Production
              </button>
              <button  class="relative inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 shadow-sm 0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
               Season
              </button>
            </div>
         
            <div 
            v-if="(currentComponent == 'CompanyMemberss' && isCreator == true) "
              class=" pt-2 lg:pt-0 text-center lg:w-auto lg:text-right top-0 lg:relative sm:inherit right-0 lg:float-right"
            >
              <!-- class= mb-2" -->
              <button @click="companyAddPeople = true"
                class="cursor-pointer px-5 py-1 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark"
              >
              + Add People
              </button>
            </div>

            <div 
            v-if="(currentComponent == 'CompanyOverview' && isCreator == true || currentComponent == 'CompanySettings' && isCreator == true ) ? setAddNewButtonForCreators(currentComponent) : false"
              class=" pt-2 lg:pt-0 text-center lg:w-auto lg:text-right top-0 lg:relative sm:inherit right-0 lg:float-right"
            >
              <!-- class= mb-2" -->
              <button @click="getProfileLink()"
                class="relative inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 shadow-sm 0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 "
              >
                Edit Company
              </button>
            </div>
          </CompanyNavbar>
          <!-- :isIconVisible="isAuthorized" -->
        </CompanyHeader>
      </div>
      <div class="col-start-0 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-7">
        <transition>
          <div>
            <component :is="currentComponent" :company="company" :editMode="isCreator" :navigation="navigation"
              :changeComponent="changeComponent" />
          </div>
        </transition>
      </div>
    </main>
    <CompanyProductionModal :modalActive="imagePoiSelectorOn" @close="setIsPhotoEditOpen(false)">
    </CompanyProductionModal>
    <CompanyAddPeopleModal :modalActive="companyAddPeople" @close="setIsCompanyAddPeople(false)">
    </CompanyAddPeopleModal>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import CompanyHeader from "@/components/companies/components/companyHeader/CompanyHeader";
import CompanyNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import CompanyOverview from "@/components/companies/viewCompany/CompanyOverview";
import CompanyMemberss from "@/components/companies/viewCompany/CompanyMembers";
// import CompanyProductions from "@/components/companies/viewCompany/CompanyProductionTest";
import CompanyProductions from "@/components/companies/viewCompany/CompanyProductionTest";
import CompanyPhotos from "@/components/companies/viewCompany/CompanyPhotos";
import CompanyVideos from "@/components/companies/viewCompany/CompanyVideos";
import CompanyDocuments from "@/components/companies/viewCompany/CompanyDocuments";
import CompanySettings from "@/components/companies/viewCompany/CompanySettings";
import CompanyProductionModal from "@/components/companies/viewCompany/CompanyProductionModal";
import CompanyAddPeopleModal from "@/components/companies/viewCompany/CompanyAddPeopleModal";

// Navigation Options
const navigation = ref([
  {
    name: "Overview",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "CompanyOverview",
    current: true,
  },
  {
    name: "Productions",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "CompanyProductions",
    current: false,
  },
  {
    name: "People",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "CompanyMemberss",
    current: false,
  },
  // {
  //   name: "Photos",
  //   onClick: function () {
  //     onNavClick(this, navigation.value);
  //   },
  //   component: "CompanyPhotos",
  //   current: false,
  // },
  // {
  //   name: "Videos",
  //   onClick: function () {
  //     onNavClick(this, navigation.value);
  //   },
  //   component: "CompanyVideos",
  //   current: false,
  // },
  // {
  //   name: "Documents",
  //   onClick: function () {
  //     onNavClick(this, navigation.value);
  //   },
  //   component: "CompanyDocuments",
  //   current: false,
  // },
  {
    name: "Company Settings",
    onClick: function () {
      onNavClick(this, navigation.value);
    },
    component: "CompanySettings",
    current: false,
  },
]);
function onNavClick(activeNode, navigation) {
  for (let i = 0; i < navigation.length; i++) {
    navigation[i].current = false;
  }
  activeNode.current = true;
}
export default {
  name: "Company",
  components: { CompanyNavbar, CompanyOverview, CompanyHeader, CompanyMemberss, CompanyProductions, CompanyPhotos, CompanyDocuments, CompanyVideos, CompanySettings, CompanyProductionModal, CompanyAddPeopleModal },
  async beforeMount() {
    await this.$store.dispatch("fetchCompany", this.$route.params.id);
    await this.$store.dispatch('fetchCompanyMembers', { first: 4, after: "", before: "", searchFilter: "", sortBy: "", companyId: this.$route.params.id })
  },
 data () {
    return {
      showCreditCard: 'hidden',
    };
  },
  methods: {
    toggle() {
      if (this.showCreditCard === 'hidden') {
        this.showCreditCard = '';
      } else {
        this.showCreditCard = 'hidden';
      }
    },
    hideToggle() {
      setTimeout(() => {
        this.showCreditCard = 'hidden' // method to call when user is done typing
      }, 3000)
    }
  },
  setup() {
    let productionMenu = ['Production', 'Season'];
    // let peopleMenu = ['Add People'];
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const addBtnOptions = ref([]);
    const imagePoiSelectorOn = ref(false)
    const companyAddPeople = ref(false)
    watch(
      () => route.params.id,
      (id, prevId) => {
        if (id && id !== prevId) store.dispatch("fetchCompany", route.params.id);
      }
    );
    // Initial setup
    store.commit("resetDynamicTableSearchParams");
    let currentComponent = ref("CompanyOverview");
    const company = computed(() => {
      return {
        ...store.getters.getCompany,
        yearsActive: store.getters.getCompanyYearsActive,
      };
    });
    function changeButtonState(target) {
      console.log("activate add new state", target, currentComponent.value)
      switch (currentComponent.value) {
        case "CompanyProductions":
          if (target == 'Production') {
            this.imagePoiSelectorOn = true
          } else if (target == 'Season') {
            // console.log("HANDLE PRODUCTION SEASON");
          } else {
            this.imagePoiSelectorOn = false
          }
          break;
        // case "CompanyMemberss":
        //   if (target == 'Add People') {
        //     this.companyAddPeople = true
        //     // router.push({ name: "AddPeople" })
        //   } else if (target == 'Department') {
        //     // console.log("HANDLE CREATE DEPARTMENT");
        //   } else {
        //     this.companyAddPeople = false
        //   }
        //   break;
        default:
          return false
      }
    }
    function setAddNewButtonForCreators(comp) {
      if (comp == 'CompanyProductions') {
        addBtnOptions.value = productionMenu;
      } 
      // else if (comp == 'CompanyMemberss') {
      //   addBtnOptions.value = peopleMenu;
      // }
      return true
      // if(isCreator){
      //    switch (comp) {
      //     case "CompanyProductions":
      //         addBtnOptions.value = ['Production','Season'];
      //         return true
      //       // console.log(addBtnOptions.value.length)
      //     case "CompanyMemberss":
      //       addBtnOptions.value = ['People','Department'];
      //       break;
      //     default:
      //       return false
      //   }
      // }else{
      //   return false;
      // }
    }
    function changeDropdown(event) {
      let button = event.target;
      button.classList.toggle('toggled');
    }
    return {
      imagePoiSelectorOn,
      companyAddPeople,
      store,
      changeButtonState,
      setAddNewButtonForCreators,
      currentComponent,
      addBtnOptions,
      company,
      navigation,
      changeDropdown,
      headerInfo: computed(() => store.getters.getCompanyHeader),
      enterEdit() {
        router.push({ name: "EditCompany", params: { id: company.value.id } });
      },
      isCreator: computed(
        () => store.getters.getId === store.getters.getCompanyCreatedBy
      ),
      changeComponent(component) {
        currentComponent.value = component;
      },
      setIsPhotoEditOpen(value) {
        imagePoiSelectorOn.value = value
      },
      setIsCompanyAddPeople(value) {
        companyAddPeople.value = value
      },
      getNavBarMenu() {
        return navigation.value.filter(function (itemss) { return itemss.component !== 'CompanySettings' });
      },
      async getProfileLink(){
      
      this.currentComponent = "CompanySettings";
      for (let i = 0; i < this.navigation.length; i++) {
        this.navigation[i].current = false;
        if(this.navigation[i].component=='CompanySettings'){
            this.navigation[i].current = true;
        }
        }
   
     
      },
    };
  },
  beforeUnmount() {
    // Reset navbar indicator
    for (let i = 0; i < this.navigation.length; i++) {
      this.navigation[i].current = false;
    }
    this.navigation[0].current = true;
  },
};
</script>
<style scoped>
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip:hover .innerBtn {
  width: 128px;
}
.shadow-basic {
  border: 1px solid #D1D5DB;
}
.shadow-basic-sm {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
</style>