<template>
<div class=" grid lg:grid-cols-3 md:grid-cols-2 md:gap-x-9 gap-y-3 flex-grow items-center">
  <ContactInfoInputField v-for="(key, idx) in keys"
                         @onInput="$emit('onInput', $event, key)"
                         :key="idx"
                         :value="item[key]"
                         :fieldName="key"
                         :suggestions="suggestions"
                         :has-error="hasError"
                         :isLast="isLast"
                         class="col-span-1"
                         :type="type[idx]"

  />
  <div class="w-4/12 flex order-last justify-start" :class="[hasError ? 'justify-between' : ' justify-start']">
    <TrashIcon v-if="!isAlone" @click="$emit('delete')" class="w-6 lg:mt-0 mt-2 cursor-pointer text-gray-500 dark:text-white"/>
    <p class="text-red-400 " v-if="hasError">{{item.error.message}}</p>
  </div>
</div>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/outline/esm";
import {computed, ref} from "vue";
import ContactInfoInputField from "@/components/inputs/ContactInfoInputField";
export default {
  name: "ContactInfoInput",
  components: {ContactInfoInputField, TrashIcon },
  props: {
    item: Object,
    keys: Array,
    isLast: Boolean,
    isAlone: Boolean,
    placeholder: String,
    suggestions: Array,
    type:Array,
  },
  setup(props){
    let showSuggestions = ref(false)
    return {
      showSuggestions,
      options: computed(() => ['Home', 'Work', 'Other']),

      hasError: computed(() => {
        if(!props.item.error) return false
        return props.item.error.hasError
      }),
    }
  },
}
</script>
